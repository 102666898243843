import { render, staticRenderFns } from "./KeyInformationBlock.vue?vue&type=template&id=407a9152&"
import script from "./KeyInformationBlock.vue?vue&type=script&lang=js&"
export * from "./KeyInformationBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports