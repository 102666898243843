<template>
    <div class="course-whiteboard-slide p-4">
        <h3 class="text-center">
            {{blockData.question}}
        </h3>
        <b-form-textarea
            v-if="blockData.lineType === 'multi'"
            v-model="quizAnswer"
            :disabled="hasAnswered"
            placeholder="Type your answer here"
            class="mt-3"
            style="field-sizing: content;min-height: 100px;"
        ></b-form-textarea>
        <b-form-input
            v-else
            v-model="quizAnswer"
            :disabled="hasAnswered"
            placeholder="Type your answer here"
            class="mt-3"
        ></b-form-input>
        <b-button
                v-if="!hasAnswered"
                block
                variant="primary"
                class="p-3 quiz-submit mt-3"
                @click="submitAnswers"
                :disabled="quizAnswer.length === 0 ? true : false"
            >
               {{ $t('submit') }}
        </b-button>
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';
export default {
    name: 'FreeTextBlock',
    components: {},
    mixins: [
        CourseBlockMixin,
    ],
    props: {
        id:{
            type: Number,
            default: null
        },
        module:{
            type: Number,
            default: null
        },
        lesson:{
            type: Number,
            default: null
        },
        slideNum:{
            type: Number,
            default: null
        }
    },
    data() {
        return {
            quizAnswer: '',
            hasAnswered: false,
        };
    },
    mounted() {
        if (this.blockData.answer){
            this.quizAnswer = this.blockData.answer;
            this.hasAnswered = true;
        }
    },
    methods:{
        async submitAnswers(){
            this.hasAnswered = true;
            this.blockData.answer = this.quizAnswer;
            this.$emit('submitAnswers', this.id, 'free-text', this.quizAnswer);
        },
    },
};
</script>
