export default {
    props: {
        blockData: {
            type: Object,
        },
        textDir: {
            type: String,
            default: 'ltr',
        },
        isRtl: {
            type: Boolean,
            default: false,
        }
    }
}
