<template>
    <div class="container min-full mid d-flex flex-column align-items-center justify-content-center">
        <transition name="fade" mode="out-in">
            <AssessmentAgreement
                v-if="section=='agreement'"
                :title="assessment.name"
                :loading="loading"
                @proceed="proceed"
                @end="end"
            />
            <AssessmentPermissions
                v-else-if="section=='permissions'"
                :permissionsStatus="permissionsStatus"
                :cameras="cameras"
                :selectedCamera="selectedCamera"
                :pending="pending"
                :cameraStream="cameraStream"
                @proceed="proceed"
                @browser="browser"
                @screenCheck="screenCheck"
                @capture="capture"
                @camera="camera"
                @fullscreen="fullscreen"
                @cameraChange="cameraChange"
            />
            <AssessmentPopup
                v-else-if="section=='popup'"
                @proceed="proceed"
            />
            <AssessmentIntro
                v-else-if="section=='intro'"
                :title="assessment.name"
                :intro="assessment.intro"
                :textDir="direction"
                :duration="duration"
                :loading="loading"
                @start="start"
                @proceed="proceed"
            />
            <AssessmentExam
                v-else-if="section=='exam'"
                :assessment="assessment"
                :textDir="direction"
                :time="time"
                :startTime="startTime"
                :cameraStream="cameraStream"
                @proceed="proceed"
                @response="response"
                @cameraFail="cameraIssue"
            />
            <AssessmentOutro
                v-else-if="section=='outro'"
                :outro="assessment.outro"
                :textDir="direction"
                :expired="expired"
                :ended="ended"
                :loading="loading"
                @proceed="proceed"
                @end="end"
                @finish="finish"
            /> 
        </transition>
        <LoadingSpinner v-if="loading" />
        <b-modal 
            :title="$t('exited')"
            :visible="exited"
            ok-only
            ok-title="Close"
            no-close-on-backdrop
            no-close-on-esc
            hide-header-close
            centered
            @ok="finish()"
        >{{ reason }}Please contact LEEA for further instructions.
        </b-modal>
    </div>
</template>
<script>

import AssessmentService from '../services/AssessmentService';
import AssessmentAgreement from '../components/Assessment/AssessmentAgreement';
import AssessmentPermissions from '../components/Assessment/AssessmentPermissions';
import AssessmentPopup from '@/components/Assessment/AssessmentPopup';
import AssessmentIntro from '../components/Assessment/AssessmentIntro';
import AssessmentExam from '../components/Assessment/AssessmentExam';
import AssessmentOutro from '../components/Assessment/AssessmentOutro';

import { DateTime } from "luxon";
import { mapGetters } from 'vuex';
import JsZip from 'jszip';
import checkTime from '../common/getCheckTime';
import LoadingSpinner from '../components/loader/Loader';
import ReadableDuration from '@/common/getReadableDuration';
import Bugsnag from '@bugsnag/js'

export default {
    name: 'AssessmentScreen',
    components: {
        AssessmentAgreement,
        AssessmentPermissions,
        AssessmentPopup,
        AssessmentIntro,
        AssessmentExam,
        AssessmentOutro,
        LoadingSpinner
    },
    computed: {
        ...mapGetters({
            permissions: 'getPermissions'
        }),
        direction(){
            if (this.assessment.is_rtl !== undefined){
                if (this.assessment.is_rtl){
                    return 'rtl';
                } else {
                    return 'ltr';
                }
            } else {
                return '';
            }
        },
    },
    data() {
        return {
            assessment: {},
            section: 'agreement',
            permissionsStatus: false,
            duration: [],
            assessmentDate: null,
            screenStream: null,
            screenTrack: null,
            cameraStream: null,
            cameraImageCapture: null,
            interval: null,
            zip: new JsZip(),
            prevP: null,
            timeout: null,
            time: null,
            startTime: null,
            loading: true,
            expired: false,
            wakeLock: null,
            exited: false,
            ended: false,
            reason: '',
            screens: null,
            user: this.$store.getters.getUser,
            cameraCount: 0,
            cameraConstraints: null,
            selectedCamera: null,
            cameras: [],
            pending: {}
        }
    },
    mounted(){
        this.$store.dispatch('resetPermissions');
        this.getAssessment();
    },
    beforeDestroy(){
        if (!this.ended){
            if (document.fullscreenElement){
                document.exitFullscreen();
            }
            if (this.cameraStream){
                this.cameraStream.getTracks().forEach(track => {
                    track.stop();
                });
            }
            if (this.screenStream){
                this.screenStream.getTracks().forEach(track => {
                    track.stop();
                });
            }
            this.$store.dispatch('resetPermissions');
        }
    },
    watch: {
        permissions: {
            deep: true,
            handler(permissions) {
                for (const permission in permissions) {
                    if (!permissions[permission]){
                        this.permissionsStatus = false;
                        if (this.section === 'exam' && !this.ended){
                            this.examFailure()
                        } else if (this.section === 'intro'){
                            this.section = 'permissions'
                        }
                        return false;
                    }
                }
                this.permissionsStatus = true;
            }
        }
    },
    methods: {
        async getAssessment() {
            try {
                const response = await AssessmentService.getAssessment(this.$route.params.id);
                this.loading = false;
                this.assessment = response.assessment;
                document.title = this.assessment.name + ' | LEEA';
                this.duration = ReadableDuration.getReadableDuration(this.assessment.duration);
                if (this.duration){
                    switch (this.duration[1]) {
                        case 'hours':
                            this.time = 1000 * 60 * 60 * this.duration[0];
                            break;
                        case 'hour':
                            this.time = 1000 * 60 * 60
                            break;
                        case 'minutes':
                            this.time = 1000 * 60 * this.duration[0];
                            break;
                        case 'minute':
                            this.time = 1000 * 60;
                            break;
                    }
                }
                if (this.assessment.status == 'Enrolled' && this.assessment.trigger_prop){
                    const date = DateTime.fromISO(this.assessment.trigger_prop);
                    const timestamp = parseInt(date.toFormat('x'));
                    const checkWindow = checkTime.getOutsideWindow(timestamp, {'days': 30});
                    if (checkWindow){
                        this.$router.push('/assessments');
                    }
                } else {
                    this.$router.push('/assessments');
                }
            } catch (error) {
                Bugsnag.notify(error);
                this.$router.push('/assessments');
            }
        },
        proceed (to, expired){
            if (expired){
                this.expired = true;
            }
            this.section = to;
            
        },
        browser () {
            if ('mediaDevices' in navigator && 'getScreenDetails' in window && 'wakeLock' in navigator && navigator.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices)  {
                this.$store.dispatch('permissionChanged', [ 'browser', true ]);
            } else {
                this.$store.dispatch('permissionChanged', [ 'browser', false ]);
            }
        },
        async screenCheck() {
            this.pending = {
                ...this.pending,
                screens: true
            }
            try {
                this.screens = await window.getScreenDetails();
                this.pending = {
                    ...this.pending,
                    screens: false
                }
                if (this.screens.screens.length === 1 || process.env.NODE_ENV === 'development') {
                    this.reason = '';
                    this.$store.dispatch('permissionChanged', [ 'screens', true ]);
                    this.screens.addEventListener('screenschange', this.screenChange);
                    navigator.permissions.query({ name: 'window-management'}).then((permissionStatus) => {
                        permissionStatus.onchange = () => {
                            if (permissionStatus.status !== 'granted' && !this.ended){
                                this.reason = 'Unable to check screens connected. '
                                this.$store.dispatch('permissionChanged', ['screens', false]);
                            }
                        }
                    });
                } else {
                    this.reason = 'Additional screens connected. '
                    this.$store.dispatch('permissionChanged', ['screens', false]);
                }
            } catch {
                this.pending = {
                    ...this.pending,
                    screens: false
                }
                this.$store.dispatch('permissionChanged', ['screens', false]);
            }
        },
        screenChange() {
            if (!this.ended){
                this.reason = 'Additional screens connected. '
                this.$store.dispatch('permissionChanged', ['screens', false]);
            }
        },
        fullscreen() {
            this.pending = {
                ...this.pending,
                fullscreen: true
            }
            document.documentElement.requestFullscreen()
            .then(() => {
                this.pending = {
                    ...this.pending,
                    fullscreen: false
                }
                this.$store.dispatch('permissionChanged', ['fullscreen', true]);
                document.addEventListener('fullscreenchange', this.fullscreenChange);
            }).catch(() => {
                this.pending = {
                    ...this.pending,
                    fullscreen: false
                }
                this.$store.dispatch('permissionChanged', ['fullscreen', false]);
            });
        },
        fullscreenChange() {
            if (!document.fullscreenElement){
                this.reason = 'You exited fullscreen. '
                this.$store.dispatch('permissionChanged', ['fullscreen', false]);
            }
        },
        capture() {
            this.pending = {
                ...this.pending,
                capture: true
            }
            navigator.mediaDevices.getDisplayMedia({
                video: {
                    cursor: 'always',
                    displaySurface: 'monitor',
                    resizeMode: 'none'
                },
                audio: false  
            }).then((stream) => {
                this.screenStream = stream;
                this.screenTrack = new ImageCapture(stream.getVideoTracks()[0]);
                this.pending = {
                    ...this.pending,
                    capture: false
                }
                this.$store.dispatch('permissionChanged', ['capture', true ]);
                navigator.permissions.query({ name: 'display-capture'}).then((permissionStatus) => {
                    permissionStatus.onchange = () => {
                        if (permissionStatus.status !== 'granted' && !this.ended){
                            this.reason = 'Unable to screen record. '
                            this.$store.dispatch('permissionChanged', ['capture', false]);
                        }
                    }
                });
                this.screenStream.getVideoTracks()[0].onended = () => {
                    if (!this.ended){
                        this.reason = 'Access to your screen recording revoked. '
                        this.$store.dispatch('permissionChanged', ['capture', false]);
                    }
                }
            }).catch((error) => {
                this.pending = {
                    ...this.pending,
                    capture: false
                }
                Bugsnag.notify(error);
                this.$store.dispatch('permissionChanged', ['capture', false]);
            });
        },
        camera(){
            this.pending = {
                ...this.pending,
                camera: true
            }
            navigator.mediaDevices.getUserMedia({
                audio: false,
                video: true
            }).catch(() => {
                this.pending = {
                    ...this.pending,
                    camera: false
                }
                this.$store.dispatch('permissionChanged', ['camera', false]);
            }).then((stream) => {
                stream.getTracks().forEach(track => {
                    track.stop();
                });
                navigator.mediaDevices.enumerateDevices()
                .then((deviceInfos) => {
                    for (var i = 0; i !== deviceInfos.length; ++i) {
                        if (deviceInfos[i].kind === 'videoinput') {
                            this.cameraCount++;
                            this.cameras.push({
                                value: deviceInfos[i].deviceId,
                                text: deviceInfos[i].label || 'Camera ' + this.cameraCount
                            });
                            if (!this.selectedCamera){
                                this.selectedCamera = deviceInfos[i].deviceId;
                            }
                        }
                    }
                });
            });
        },
        cameraChange(cameraInput){
            this.selectedCamera = cameraInput;
            if (this.cameraStream) {
                this.cameraStream.getTracks().forEach(track => {
                    track.stop();
                });
            }
            this.getCameraStream();
        },
        getCameraStream(){
            this.cameraConstraints = {
                video: {deviceId: this.selectedCamera ? {exact: this.selectedCamera} : undefined}
            };
            navigator.mediaDevices.getUserMedia(this.cameraConstraints)
                .then((stream) => {
                    this.cameraStream = stream;
                    this.cameraImageCapture = new ImageCapture(stream.getVideoTracks()[0]);
                    this.$store.dispatch('permissionChanged', ['camera', true ]);
                    this.pending.camera = false;
                })
                .catch((error) => {
                    this.pending = {
                        ...this.pending,
                        camera: false
                    }
                    Bugsnag.notify(error);
                    this.$store.dispatch('permissionChanged', ['camera', false]);
                })
        },
        async start() {
            this.loading = true;
            this.wakeLock = await navigator.wakeLock.request('screen');
            AssessmentService.getAssessment(this.$route.params.id)
            .then((resp) => {
                if (resp.success && resp.assessment.status === 'Enrolled'){
                    this.collectEvidence();
                    AssessmentService.updateStatus(this.assessment.id, 'Started');
                    this.$store.dispatch('setAssessment', this.assessment.id);
                    this.wakeLock.addEventListener('release', this.examFailure);
                    navigator.permissions.query({ name: 'camera'}).then((permissionStatus) => {
                        permissionStatus.onchange = () => {
                            if (permissionStatus.status !== 'granted' && !this.ended){
                                this.reason = 'Access to your camera revoked. '
                                this.$store.dispatch('permissionChanged', ['camera', false]);
                            }
                        }
                    });
                    this.cameraStream.getVideoTracks()[0].onended = () => {
                        if (!this.ended){
                            this.reason = 'Access to your camera revoked. '
                            this.$store.dispatch('permissionChanged', ['camera', false]);
                        }
                    }
                    window.addEventListener('blur', this.examFailure);
                    this.loading = false;
                    this.proceed('exam');
                } else {
					this.$router.push('/assessments');
				}
            });
        },
        collectEvidence() {
            if (!this.ended){
                this.screenCheck();
                this.cameraImageCapture.grabFrame()
                    .then((camerabitmap) => {
                        this.processEvidence(camerabitmap, 'camera')
                        this.screenTrack.grabFrame()
                        .then((screenbitmap) => {
                            this.processEvidence(screenbitmap, 'screen');
                            this.interval = setTimeout(() => {
                                this.collectEvidence();
                            }, 30000);
                        })
                        .catch((error) => {
                            Bugsnag.notify(error);
                            this.interval = setTimeout(() => {
                                this.collectEvidence();
                            }, 30000);
                        })
                    })
                    .catch((error) => {
                        Bugsnag.notify(error);
                        this.interval = setTimeout(() => {
                            this.collectEvidence();
                        }, 30000);
                    });
            }
        },
        processEvidence(bitmap, type) {
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            canvas.width = bitmap.width;
            canvas.height = bitmap.height;
            context.drawImage(bitmap, 0, 0);
            const date = new Date();
            const timestamp = date.getDate() + '' + (date.getMonth() + 1) + '' + date.getFullYear() + 'T' + date.getHours() + '-' + date.getMinutes() + '-' + date.getSeconds();
            canvas.toBlob((blob) => {
                this.zip.folder(type).file(timestamp + '.jpg', blob);
            });
        },
        response(question, answer){
            this.assessment.blocks[question].response = [answer];
        },
        async end() {
            try {
                clearTimeout(this.timeout);
                this.loading = true;
                this.ended = true;
                this.screens.removeEventListener('screenchange', this.screenChange);
                document.removeEventListener('fullscreenchange', this.fullscreenChange);
                window.removeEventListener('blur', this.examFailure);
                this.wakeLock.release()
                .then(() => {
                    this.wakeLock = null;
                    if (document.fullscreenElement){
                        document.exitFullscreen();
                    }
                    this.cameraStream.getTracks().forEach(track => {
                        track.stop();
                    });
                    this.screenStream.getTracks().forEach(track => {
                        track.stop();
                    });
                    this.$store.dispatch('setAssessment', null);
                    this.$store.dispatch('resetPermissions');
                    if (!this.reason){
                        this.sendData();
                    }
                });
                
            } catch (error) {
                Bugsnag.notify(error);
            }
        },
        async sendData() {
            const zipBlob = await this.zip.generateAsync({type: "blob"});
            const zipFile = new File([zipBlob], 'security.zip');
            const formData = new FormData();
            let responseData = [];
            this.assessment.blocks.forEach((block) => {
                responseData.push({
                    id: block.id,
                    response: block.response ? block.response : []
                });
            });
            const fileName = `${process.env.VUE_APP_API_COMPANY_ID}/${this.user.id}/${this.assessment.id}/${Date.now()}.zip`;
            formData.append('key', fileName);
            formData.append('blocks', JSON.stringify(responseData));
            try {
                const response = await AssessmentService.submitAssessment(this.assessment.id, formData);
                if (response.success && response.preSignedUrl){
                    try {
                        const awsResponse = await fetch(response.preSignedUrl, {
                            method: 'PUT',
                            body: zipFile
                        });
                        if (!awsResponse.ok){
                            throw new Error(awsResponse.status);
                        }
                        await AssessmentService.updateStatus(this.assessment.id, 'Submitted with Evidence');
                        this.loading = false;
                    } catch (error) {
                        await AssessmentService.updateStatus(this.assessment.id, 'Submitted without Evidence');
                        Bugsnag.notify(error);
                        this.loading = false;
                    }
                } else {
                    throw response;
                }
            } catch (error) {
                Bugsnag.notify(error, (event) => {
                    event.addMetadata('Response Data', responseData);
                });
                AssessmentService.updateStatus(this.assessment.id, 'Exited', 'FailToUpload');
                this.loading = false;
            }
        },
        finish() {
            window.onbeforeunload = null;
            this.$router.push('/assessments');
        },
        cameraIssue() {
            this.reason = 'We detected an issue with your camera. '
            this.examFailure();
        },
        examFailure() {
            if (!this.ended){
                this.ended = true;
                this.loading = true;
                if (!this.reason){
                    this.reason = 'We detected you switched away from the assessment. '
                }
                this.end();
                AssessmentService.updateStatus(this.assessment.id, 'Exited', 'Security')
                .then(() => {
                    this.exited = true;
                });
            }
        }
    }
}
</script>
