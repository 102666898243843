/* eslint-disable no-console */

import { register } from 'register-service-worker'

const swUrl = `${process.env.BASE_URL}service-worker.js`;

if (process.env.NODE_ENV === 'production') {
    register(swUrl, {
        ready () {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            )
        },
        registered (r) {
            console.log('Service worker has been registered.');
            r && setInterval(async () => {
                if (!(!r.installing && navigator))
                  return
          
                if (('connection' in navigator) && !navigator.onLine)
                  return
          
                try {
                  const resp = await fetch(swUrl, {
                    method: 'HEAD',
                    cache: 'no-store',
                    headers: {
                      'cache': 'no-store',
                      'cache-control': 'no-cache',
                    },
                  })
          
                  if (resp?.status === 200)
                    await r.update()
                }
                catch {
                    console.log('An issue occured while checking for updates.');
                }
              }, 1800000)
        },
        cached () {
            console.log('Content has been cached for offline use.')
        },
        updatefound () {
            console.log('New content is downloading.')
        },
        updated (registration) {
            document.dispatchEvent(new CustomEvent('swUpdated', { detail: registration }));
        },
        offline () {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error (error) {
            console.error('Error during service worker registration:', error)
        }
    });
}