import CourseService from "@/services/CourseService";

export default {
    data() {
        return {

            whitelabel: {
                colours: null,
                logo: null,
                platformName: null,
                font: null,
            },
        };
    },
    methods: {
        getCompanyBranding() {
            CourseService.getCompanyBrandingByHost().then(
                (resp) => {
                    if (resp.status == 200) {
                        resp.data.settings_with_names.forEach((setting) => {
                            switch (setting.name) {
                                case 'platform_name':
                                    this.whitelabel.platformName = setting.pivot.value;
                                    break;
                                case 'brand_colours':
                                    this.whitelabel.colours = JSON.parse(setting.pivot.value)[0];
                                    break;
                                case 'brand_logo':
                                    this.whitelabel.logo = setting.pivot.value;
                                    break;
                                case 'font':
                                    this.whitelabel.font = setting.pivot.value;
                                    break;
                            }
                        });
                        this.loadBrand();
                    } else {
                        this.loadBrand();
                    }
                }
            );
        },
        loadBrand() {
            if (this.whitelabel.colours === null || this.whitelabel.colours === '{}') {
                this.whitelabel.colours = { primary: '#42a2dc', secondary: '#EDF8FD' }
            }
            if (this.whitelabel.logo === null) {
                this.whitelabel.logo = '/images/logo.png';
            }
            if (this.whitelabel.platformName === null) {
                this.whitelabel.platformName = 'Nucleus Learning';
            }
            const objRoot = document.querySelector(':root');

            let customCounter = 0;
            let keys = Object.keys(this.whitelabel.colours);

            keys.forEach(key => {
                let value = this.whitelabel.colours[key];
                customCounter++;
                objRoot.style.setProperty('--custom-' + customCounter + '-primary-red', value.primary.red);
                objRoot.style.setProperty('--custom-' + customCounter + '-primary-green', value.primary.green);
                objRoot.style.setProperty('--custom-' + customCounter + '-primary-blue', value.primary.blue);
                objRoot.style.setProperty('--custom-' + customCounter + '-secondary-red', value.secondary.red);
                objRoot.style.setProperty('--custom-' + customCounter + '-secondary-green', value.secondary.green);
                objRoot.style.setProperty('--custom-' + customCounter + '-secondary-blue', value.secondary.blue);
                objRoot.style.setProperty('--custom-' + customCounter + '-text-color', value.text);
                objRoot.style.setProperty('--custom-' + customCounter + '-styledtext-color', pSBC(0.6, 'rgb(' + value.primary.red + ',' + value.primary.green + ',' + value.primary.blue + ')'));

                if (customCounter === 1) {
                    //objRoot.style.setProperty('--secondary-brand-color', pSBC(0.3,'rgb('+value.primary.red+','+value.primary.green+','+value.primary.blue+')'));
                    //objRoot.style.setProperty('--secondary-brand-text-color', 'var(--primary-brand-color)');
                }
            });
        },
    }
}

// Version 4.1
const pSBC = (p, c0, c1, l) => {
    let r, g, b, P, f, t, h, m = Math.round, a = typeof (c1) == "string";
    if (typeof (p) != "number" || p < -1 || p > 1 || typeof (c0) != "string" || (c0[0] != 'r' && c0[0] != '#') || (c1 && !a)) return null;
    h = c0.length > 9, h = a ? c1.length > 9 ? true : c1 == "c" ? !h : false : h, f = pSBC.pSBCr(c0), P = p < 0, t = c1 && c1 != "c" ? pSBC.pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 }, p = P ? p * -1 : p, P = 1 - p;
    if (!f || !t) return null;
    if (l) r = m(P * f.r + p * t.r), g = m(P * f.g + p * t.g), b = m(P * f.b + p * t.b);
    else r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5), g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5), b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
    a = f.a, t = t.a, f = a >= 0 || t >= 0, a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0;
    if (h) return "rgb" + (f ? "a(" : "(") + r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "") + ")";
    else return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2)
}

pSBC.pSBCr = (d) => {
    const i = parseInt;
    let n = d.length, x = {};
    if (n > 9) {
        const [r, g, b, a] = (d = d.split(','));
        n = d.length;
        if (n < 3 || n > 4) return null;
        x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4)), x.g = i(g), x.b = i(b), x.a = a ? parseFloat(a) : -1
    } else {
        if (n == 8 || n == 6 || n < 4) return null;
        if (n < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
        d = i(d.slice(1), 16);
        if (n == 9 || n == 5) x.r = d >> 24 & 255, x.g = d >> 16 & 255, x.b = d >> 8 & 255, x.a = Math.round((d & 255) / 0.255) / 1000;
        else x.r = d >> 16, x.g = d >> 8 & 255, x.b = d & 255, x.a = -1
    } return x
};
