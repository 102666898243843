import store from '@/store';
import router from '@/router';
import CourseMedia from '@/common/getCourseMedia';

export default {

    async getCourses() {
        const url = process.env.VUE_APP_APIURL + '/api/v1/courses/';
        const response = await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${store.getters.getToken}`
            }
        });
        if (response.ok){
            store.dispatch('setMaintenanceMode', [false]);
            const responseJson = await response.json();
            responseJson.courses.forEach((course, index) => {
                let img = 'course_image';
                if (store.getters.getIsTrainer){
                    img = 'course_image_url'
                }
                if (course[img] && !course[img].includes('s3.eu-west-2.amazonaws.com') && !course[img].startsWith('http')){
                    course[img] = process.env.VUE_APP_APIURL + course[img];
                }
                if (!course[img]){
                    course[img] = '/img/thumbnails/nucleus-default-course-image.jpg';
                }
                responseJson.courses[index] = course;
            });
            return responseJson;
        } else if (response.status === 503){
            const responseJson = await response.json();
            store.dispatch('setMaintenanceMode', [true, responseJson.message]);
        } else if (response.status === 401){
            store.dispatch('setMaintenanceMode', [false]);
            store.dispatch('logout');
            router.push('/login');
            const cacheNames = await caches.keys();
            await Promise.all(cacheNames.map(async (cacheName) => {
                if (cacheName.startsWith('course')){
                    return await caches.delete(cacheName);
                }
            }));
        } else {
            store.dispatch('setMaintenanceMode', [false]);
            throw new Error(response.statusText);
        }
    },

    async getCourse(courseID, download) {
        const url = process.env.VUE_APP_APIURL + '/api/v1/course/' + courseID + (download ? '?cache=true' : '');
        const response = await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${store.getters.getToken}`
            }
        });
        if (response.ok) {
            store.dispatch('setMaintenanceMode', [false]);
            let responseJson = await response.json();
            const course = await CourseMedia.getMediaURLS(responseJson.course, download, store.getters.getIsTrainer);
            responseJson.course = course;
            if (download){
                await this.getCompanyBrandingByHost();
            }
            return responseJson;
        } else if (response.status === 503){
            const responseJson = await response.json();
            store.dispatch('setMaintenanceMode', [true, responseJson.message]);
        } else if (response.status === 401){
            store.dispatch('setMaintenanceMode', [false]);
            store.dispatch('logout');
            router.push('/login');
            const cacheNames = await caches.keys();
            await Promise.all(cacheNames.map(async (cacheName) => {
                if (cacheName.startsWith('course')){
                    return await caches.delete(cacheName);
                }
            }));
        } else {
            throw new Error(response.statusText);
        }
    },

    async startCourse(courseID, courseData) {
        const request = new Request(process.env.VUE_APP_APIURL + '/api/v1/course/' + courseID + '/start', {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${store.getters.getToken}`
            }
        });
        try {
            const response = await fetch(request);
            if (response.ok){
                const responseJson = await response.json();
                return responseJson;
            } else {
                throw new Error(response.statusText);
            }
        } catch (error) {
            await this.cacheRequest({
                course: courseID,
                type: 'startCourse'
            }, courseData);
        }
    },

    async completeLesson(courseID, lessonID, courseData) {
        const request = new Request(process.env.VUE_APP_APIURL + '/api/v1/course/' + courseID + '/complete-lesson', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.getToken}`
            },
            body: JSON.stringify({
                lessonId: String(lessonID)
            })
        });
        try {
            const response = await fetch(request);
            if (response.ok){
                const responseJson = await response.json();
                return responseJson;
            } else {
                throw new Error ('Unsuccessful response')
            }
        } catch (error){
            await this.cacheRequest({
                course: courseID,
                type: 'completeLesson',
                data: lessonID
            }, courseData);
        }
    },
    async finishCourse(courseID, courseData) {
        const request = new Request(process.env.VUE_APP_APIURL + '/api/v1/course/' + courseID + '/finish', {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${store.getters.getToken}`
            }
        });
        try {
            const response = await fetch(request);
            if (response.ok){
                const responseJson = await response.json();
                return responseJson;
            } else {
                throw new Error(response.statusText);
            }
        } catch (error) {
            await this.cacheRequest({
                course: courseID,
                type: 'finishCourse',

            }, courseData);
        }
    },
    async submitQuiz(courseID, quizData, courseData) {
        const request = new Request(process.env.VUE_APP_APIURL + '/api/v1/course/' + courseID + '/submit-update', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.getToken}`
            },
            body: JSON.stringify({
                response_data: quizData
            })
        });
        try {
            const response = await fetch(request);
            if (response.ok){
                const responseJson = await response.json();
                return responseJson;
            } else {
                throw new Error (response.statusText)
            }
        } catch (error){
            await this.cacheRequest({
                course: courseID,
                type: 'submitQuiz',
                data: quizData
            }, courseData);
        }
    },
    async getCompanyBrandingByHost() {
        let url = process.env.VUE_APP_APIURL + '/api/company-branding-host-login?company_id=' + process.env.VUE_APP_API_COMPANY_ID;
        const response = await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${store.getters.getToken}`
            }
        });
        if (response.ok){
            const responseJson = await response.json();
            if (responseJson.message === 'OK: Successfully retrieved company branding'){
                return responseJson;
            } else {
                throw new Error (response.statusText);
            }
        } else {
            throw new Error(response.statusText);
        }
    },
    async cacheRequest(storeObject, courseData){
        const cache = await caches.open('courses');
        let courseCache = await cache.match(process.env.VUE_APP_APIURL + '/api/v1/course/' + storeObject.course + '?cache=true');
        if (courseData && courseCache){
            const newResponse = new Response(JSON.stringify(courseData), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            await cache.put(process.env.VUE_APP_APIURL + '/api/v1/course/' + storeObject.course + '?cache=true', newResponse);
            store.dispatch('addToRequestQueue', storeObject);
        } else if (courseCache) {
            store.dispatch('addToRequestQueue', storeObject);
        } else {
            //store.dispatch('toasting', 'offlineNotDownloaded');
        }
    }
};
