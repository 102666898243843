
<template>
    <div
        class="course-whiteboard-slide image_hotspot" ref="hotspotSlide"
    >
        <div
            class="banner_photo w-100 h-100 d-flex justify-content-center align-items-center"
            :class="{
                'h-100': isWhiteboard,
                'image-area': !isWhiteboard,
            }"
        >
            <div class="imageFit" ref="hotspotSize">
                <b-img
                    v-if="!blockData.hotspot_information.hotspotPreviewList"
                    :src="blockData.hotspot_information.hotspotList.image_url"
                    alt=""
                    @load="getImgSize"
                    ref="hotspotImg"
                />

                <template
                    v-if="blockData.hotspot_information.hotspotTooltip"
                >
                    <template 
                        v-for="(hotspot, idx) in blockData.hotspot_information.hotspotTooltip">
                        <a
                            :key="`hotspotCircle${idx}-${uniqueID}`"
                            :id="`popover-target-${idx}-${uniqueID}`"
                            href="javascript:void(0)"
                            class="hot-round"
                            
                            :class="{'active': hotspot.popover}"
                            :style="{
                                top: `${hotspot.top}%`,
                                left: `${hotspot.left}%`
                            }"
                            v-on:mousedown.stop
                        />
                        <b-popover
                            :key="`hotspotPopover${idx}-${uniqueID}`"
                            :target="`popover-target-${idx}-${uniqueID}`"

                            triggers="focus hover"
                            custom-class="overlay-popover hotspot-popover"
                            @show="onShow(idx)"
                            
                        >
                            <h3 :class="`txt-dir-${textDir}`">{{ hotspot.title!=''?hotspot.title:'Enter title'}}</h3>
                            <p :class="`txt-dir-${textDir}`">{{ hotspot.content!=''?hotspot.content:'Enter details'}}</p>
                        </b-popover>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';
export default {
    name: 'HotspotBlock',
    components: {},
    mixins: [
        CourseBlockMixin,
    ],

    props: {
        slideNum: {
            type: Number,
            default: null
        }, 
        id:{
            type: Number,
            default: null
        },
        module:{
            type: Number,
            default: null
        },
        lesson:{
            type: Number,
            default: null
        }
    },
    data() {
        return {
            slideWidth: 0,
            slideHeight: 0,
            imgWidth: 0,
            imgHeight: 0,
            isWhiteboard: false,
        };
    },
    mounted() {
        window.addEventListener('resize', this.getSlideSize());
    },
    unmounted(){
        window.removeEventListener('resize', this.getSlideSize());
    },
    computed: {
        isWhiteboardCheck() {
            const parent = document.querySelectorAll('.course-whiteboard');

            return parent.length && parent[0].contains(this.$refs.imgSlide);
        },
        uniqueID() {
            if (this.slideNum){
                return this.slideNum
            } else {
                return '' + this.module + this.lesson + this.id
            }
        }
    },
    methods:{
        getSlideSize() {
            this.isWhiteboard = this.isWhiteboardCheck;
            if (this.isWhiteboard) {
                this.slideWidth = this.$refs.hotspotSlide.clientWidth;
                this.slideHeight = this.$refs.hotspotSlide.clientHeight;

                if (this.imgWidth/this.imgHeight >= this.slideWidth / this.slideHeight) {
                    const r = this.slideWidth / this.imgWidth;
                    this.$refs.hotspotSize.style.width = this.slideWidth + 'px';
                    this.$refs.hotspotSize.style.height = (this.imgHeight * r) + 'px';
                } else {
                    const r = this.slideHeight / this.imgHeight;
                    this.$refs.hotspotSize.style.height = this.slideHeight + 'px';
                    this.$refs.hotspotSize.style.width = (this.imgWidth * r) + 'px';
                }
            }
        },
        getImgSize() {
            this.imgWidth = this.$refs.hotspotImg.naturalWidth;
            this.imgHeight = this.$refs.hotspotImg.naturalHeight;
            this.getSlideSize();
        },
        onShow(identifier) {
            this.$emit('interacted', this.id, identifier);
        }
    },
};
</script>
