<template>
    <div class="course-whiteboard-toolbar p-3">
        <b-button-toolbar key-nav aria-label="Toolbar with button groups" class="w-100 justify-content-between">
            <b-button-group
                class="mode-buttons"
            />

            <b-button-group>
                <div class="course-whiteboard-toolbar-buttons">
                    <b-button
                        class="course-whiteboard-toolbar-button course-whiteboard-toolbar-button--prev"
                        :class="{
                            'disabled': isDrawing
                        }"
                        @click.prevent="prevSlide"
                    >
                        <i class="fa fa-angle-left"></i>
                    </b-button>
                    <div
                        class="course-whiteboard-toolbar-numbers"
                        :class="{
                            'disabled': isDrawing
                        }"
                    >
                        <input
                            type="number"
                            class="course-whiteboard-toolbar-currentSlide"
                            :value="friendlyCurrentSlide"
                            @change="updateSlide"
                        />
                    </div>
                    <b-button
                        v-if="!isDrawing"
                        class="course-whiteboard-toolbar-button-circle course-whiteboard-toolbar-button--draw"
                        :disabled="isDrawing"
                        @click="isDrawing = true"
                        alt="Start Drawing"
                    >
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                    </b-button>
                    <b-button
                        v-else
                        class="course-whiteboard-toolbar-button-circle course-whiteboard-toolbar-button--arrow"
                        :disabled="!isDrawing"
                        @click="isDrawing = false"
                        alt="Stop Drawing"
                    >
                        <i class="fa fa-mouse-pointer" aria-hidden="true"></i>
                    </b-button>
                    <b-button
                        class="course-whiteboard-toolbar-button course-whiteboard-toolbar-button--next"
                        :class="{
                            'disabled': isDrawing
                        }"
                        @click.prevent="nextSlide"
                    >
                        <i class="fa fa-angle-right"></i>
                    </b-button>
                </div>
            </b-button-group>

            <div>
                <b-button
                    class="course-whiteboard-toolbar-button-circle course-whiteboard-toolbar-button--exit"
                    alt="Close Whiteboard Mode"
                    @click.prevent="close"
                >
                    <i class="fa fa-times"></i>
                </b-button>
            </div>
        </b-button-toolbar>
    </div>
</template>

<script>


export default {
    name: 'WhiteboardToolbar',

    props: {
        totalSlides: {
            type: Number,
            required: true,
        },
        value: {
            type: Number,
            default: 0,
        },
        carousel: {
            type: Object,
            default: null,
        },
        drawMode: {
            type: Boolean,
            default: false,
        },

        canvasRef: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            currentSlide: this.value + 1,
        };
    },

    mounted() {},

    computed: {
        friendlyCurrentSlide() {
            return this.value + 1;
        },

        isDrawing: {
            get() {
                return this.drawMode;
            },
            set(value) {
                this.$emit('toggleDrawMode', value);
            }
        },
    },

    methods:{
        prevSlide() {
            if (this.carousel) {
                this.carousel.slidePrev();
            }
        },

        updateSlide(evt) {
            let value = evt.target.value - 1;

            if (Number.isInteger(value)) {
                if (value < 0) {
                    value = 0;
                } else if (value > this.totalSlides - 1) {
                    value = this.totalSlides - 1;
                }
            } else {
                value = 0;
            }

            this.carousel.slideTo(value);
        },

        nextSlide() {
            if (this.carousel) {
                this.carousel.slideNext();
            }
        },

        close() {
            if (this.isDrawing) {
                this.isDrawing = false;
                return;
            }

            this.$emit('close');
        },
    },
};
</script>
