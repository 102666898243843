<template>
    <router-link
        :to="this.user.type === 'trainer' ? '/courses/' + course.id + '/whiteboard' : '/courses/' + course.id"
        :class="`list-group-item course__item ${direction} ${!availability ? 'disabled' : ''}`"
    >
        <div class="row">
            <div class="col-3">
                <div class="aspectRatio">
                    <div>
                        <img class="imgFit" :src="$store.getters.getIsTrainer ? course.course_image_url : course.course_image" alt="" crossorigin="anonymous"/>
                    </div>
                </div>
            </div>
            <div class="col-9 d-flex align-middle align-items-center">
                <div>
                    <h2
                        v-if="course.name"
                        class="mb-1"
                    >
                        {{ course.name }}
                    </h2>
                    <p :class="'mb-0 ' + $store.getters.getDirection">
                        <template v-if="endDate">{{ $t('validUntil') }}: {{ endDate }}<br></template>
                        <template v-if="user.type !== 'trainer' && (course.status === 'Submitted' || course.status === 'Failed' || course.status === 'Passed')">{{ $t('submitted') }}</template>
                    </p>
                    <b-button class="course__item__dl" v-if="downloaded" @click.prevent="deleteCourse()"><svg-icon icon="delete"></svg-icon></b-button>
                    <b-button class="course__item__dl" v-else-if="online && this.$store.getters.getIsTrainer" @click.prevent="downloadCourse()" :disabled="processing">
                        <svg-icon v-if="!processing" icon="download"></svg-icon>
                        <b-spinner v-else small label="Downloading..."></b-spinner>
                    </b-button>
                </div>
            </div>
        </div>
    </router-link>
</template>
<script>

import ReadableTime from '../common/getReadableTime';
import SvgIcon from '@/components/SvgIcon';
import CourseService from '../services/CourseService';
import Bugsnag from '@bugsnag/js';

export default {
    name: 'CourseBlock',
    props: {
        course: {
            required: true
        }
    },
    components: {
        SvgIcon
    },
    computed: {
        direction(){
            if (this.course.is_rtl !== undefined){
                if (this.course.is_rtl){
                    return 'rtl';
                } else {
                    return 'ltr';
                }
            } else {
                return '';
            }
        },
        online() {
            return navigator.onLine;
        },
        endDate() {
            if (this.$store.getters.getIsTrainer || this.course.is_evergreen){
                return null;
            }
            return ReadableTime.getReadableTime(this.course.end_date);
        },
        availability() {
            if (navigator.onLine || this.downloaded){
                return true;
            }
            return false;
        }
    },
    data(){
        return {
            user: this.$store.getters.getUser,
            processing: false,
            cache: null,
            downloaded: false
        }
    },
    async created() {
        this.cache = await caches.open('courses');
        this.downloaded = await this.cache.match(process.env.VUE_APP_APIURL + '/api/v1/course/' + this.course.id + '?cache=true') ? true : false;
    },
    methods: {
        async downloadCourse() {
            this.processing = true;
            try {
                await CourseService.getCourse(this.course.id, true);
                this.downloaded = true;
                this.processing = false;
                this.$bvToast.toast(this.course.name + ' is available for offline use.', {
                    title: 'Course Successfully Downloaded',
                    toaster: 'b-toaster-bottom-right',
                    id: '' + this.course.id,
                    autoHideDelay: 5000,
                    appendToast: false
                });
            } catch(error) {
                console.log(error);
                this.processing = false;
                Bugsnag.notify(error);
            }
        },
        async deleteCourse() {
            this.processing = true;
            try {
                await CourseService.getCourse(this.course.id, 'delete');
                await this.cache.delete(process.env.VUE_APP_APIURL + '/api/v1/course/' + this.course.id + '?cache=true');
                this.downloaded = false;
                this.processing = false;
                this.$bvToast.toast(this.course.name + ' is no longer available offline.', {
                    title: 'Course Successfully Deleted',
                    id: '' + this.course.id,
                    toaster: 'b-toaster-bottom-right',
                    autoHideDelay: 5000,
                    appendToast: false
                });
            } catch (error){
                console.log(error);
                this.processing = false;
                Bugsnag.notify(error);
            }
        }
    }
}

</script>
