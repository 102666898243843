<template>
    <div v-if="!loading">
        <b-link class="back" @click="leave"><svg-icon icon="back" /></b-link>
        <h1>{{ title }}</h1>
        <div class="agreement">
            <div class="agreement__box">
                <p>{{ $t('agree1')}}</p>
                <p>{{ $t('agree2')}}</p>
                <p>{{ $t('agree3')}}</p>
                <p>{{ $t('agree4')}}</p>
                <p>{{ $t('agree5')}}</p>
                <p>{{ $t('agree6')}}</p>
                <p>{{ $t('agree7')}}</p>
            </div>
            <b-button class="p-3 mt-3" block variant="primary" @click="$emit('proceed', 'permissions')">{{ $t('agree') }}</b-button>
        </div>
    </div>
</template>
<script>
import SvgIcon from '@/components/SvgIcon';

export default {
  components: { SvgIcon },
    name: "AssessmentAgreement",
    props: {
        title: String,
        loading: Boolean
    },
    methods: {
        leave(){
            this.$router.push('/assessments');
        }
    }
}

</script>