import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import router from './router';
import store from './store';
import i18n from './translations';
import App from './App.vue';
import device from 'vue-device-detector';
import './vee-validate';
import './registerServiceWorker';

import './app.scss';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(device);

const connectInfo = 'connection' in navigator ? {
    speed: navigator.connection.downlink,
    useLowData: navigator.connection.saveData
} : null;

Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API,
    plugins: [new BugsnagPluginVue()],
    releaseStage: process.env.VUE_APP_BUGSNAG_STAGE,
    enabledReleaseStages: ['production', 'staging'],
    appVersion: process.env.VUE_APP_VERSION,
    metadata: {
        'Connection Info': connectInfo,
        'App': {
            microsoftStore: document.referrer === 'app-info://platform/microsoft-store' ? true : false
        }
    },
    onError: function (event) {
        var user = store.getters.getUser;
        if (user) {
            event.setUser(user.id, user.email, user.name);
        }
    },
});

const bugsnagVue = Bugsnag.getPlugin('vue');
bugsnagVue.installVueErrorHandler(Vue);

new Vue({
    store,
    router,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
