<template>
    <div
        v-if="blockData.imageList.image_url.length > 0"
        class="course-whiteboard-slide w-100 d-flex justify-content-center align-items-center image_block"
        :class="{
            'h-100': isWhiteboard,
        }"
        ref="imgSlide"
    >
        <b-img
            :src="blockData.imageList.image_url"
            alt=""
            ref="img"
        />
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';

export default {
    name: 'ImageBlock',
    components: {},

    mixins: [CourseBlockMixin],

    props: {},
    data() {
        return {
            slideWidth: 0,
            slideHeight: 0,
            imgWidth: 0,
            imgHeight: 0,
            isWhiteboard: false,
        };
    },
    mounted() {
        window.addEventListener('resize', this.getSlideSize());
    },
    unmounted(){
        window.removeEventListener('resize', this.getSlideSize());
    },
    computed: {
        isWhiteboardCheck() {
            const parent = document.querySelectorAll('.course-whiteboard');

            return parent.length && parent[0].contains(this.$refs.imgSlide);
        }
    },
    methods:{
        getSlideSize() {
            this.isWhiteboard = this.isWhiteboardCheck;
            if (this.isWhiteboard) {
                this.slideWidth = this.$refs.imgSlide.clientWidth;
                this.slideHeight = this.$refs.imgSlide.clientHeight;
                if (this.imgWidth >= this.slideWidth || this.imgHeight >= this.slideHeight){
                    if (this.imgWidth/this.imgHeight >= this.slideWidth / this.slideHeight) {
                        const r = this.slideWidth / this.imgWidth;
                        this.$refs.img.style.width = this.slideWidth + 'px';
                        this.$refs.img.style.height = (this.imgHeight * r) + 'px';
                    } else {
                        const r = this.slideHeight / this.imgHeight;
                        this.$refs.img.style.height = this.slideHeight + 'px';
                        this.$refs.img.style.width = (this.imgWidth * r) + 'px';
                    }
                }
            }
        },
        getImgSize() {
            this.imgWidth = this.$refs.img.naturalWidth;
            this.imgHeight = this.$refs.img.naturalHeight;
            this.getSlideSize();
        }
    },
};
</script>
