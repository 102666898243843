var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course-whiteboard-toolbar p-3"},[_c('b-button-toolbar',{staticClass:"w-100 justify-content-between",attrs:{"key-nav":"","aria-label":"Toolbar with button groups"}},[_c('b-button-group',{staticClass:"mode-buttons"}),_c('b-button-group',[_c('div',{staticClass:"course-whiteboard-toolbar-buttons"},[_c('b-button',{staticClass:"course-whiteboard-toolbar-button course-whiteboard-toolbar-button--prev",class:{
                        'disabled': _vm.isDrawing
                    },on:{"click":function($event){$event.preventDefault();return _vm.prevSlide.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-angle-left"})]),_c('div',{staticClass:"course-whiteboard-toolbar-numbers",class:{
                        'disabled': _vm.isDrawing
                    }},[_c('input',{staticClass:"course-whiteboard-toolbar-currentSlide",attrs:{"type":"number"},domProps:{"value":_vm.friendlyCurrentSlide},on:{"change":_vm.updateSlide}})]),(!_vm.isDrawing)?_c('b-button',{staticClass:"course-whiteboard-toolbar-button-circle course-whiteboard-toolbar-button--draw",attrs:{"disabled":_vm.isDrawing,"alt":"Start Drawing"},on:{"click":function($event){_vm.isDrawing = true}}},[_c('i',{staticClass:"fa fa-pencil",attrs:{"aria-hidden":"true"}})]):_c('b-button',{staticClass:"course-whiteboard-toolbar-button-circle course-whiteboard-toolbar-button--arrow",attrs:{"disabled":!_vm.isDrawing,"alt":"Stop Drawing"},on:{"click":function($event){_vm.isDrawing = false}}},[_c('i',{staticClass:"fa fa-mouse-pointer",attrs:{"aria-hidden":"true"}})]),_c('b-button',{staticClass:"course-whiteboard-toolbar-button course-whiteboard-toolbar-button--next",class:{
                        'disabled': _vm.isDrawing
                    },on:{"click":function($event){$event.preventDefault();return _vm.nextSlide.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-angle-right"})])],1)]),_c('div',[_c('b-button',{staticClass:"course-whiteboard-toolbar-button-circle course-whiteboard-toolbar-button--exit",attrs:{"alt":"Close Whiteboard Mode"},on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-times"})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }