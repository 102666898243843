import { DateTime } from "luxon";
import store from '../store';

export default {

    getReadableTime(timestamp) {
        if (timestamp){
            const date = DateTime.fromMillis(timestamp).setLocale(store.getters.getLanguage);
            return date.toFormat('dd LLL yyyy, HH:mm');
        }
        return '';
    }
    
}