<template>
    <p class="text-primary text-center">{{ $t('question') + ' ' + questionNum + '/' + totalQuestions + ' | ' + $t('timeRemaining') + ': ' + readableTime }}</p>
</template>

<script>
import { DateTime } from "luxon";

export default {
    props: {
        time: Number,
        questionNum: Number,
        totalQuestions: Number
    },
    data() {
        return {
            startTime: DateTime.now(),
            endTime: null,
            interval: null,
            readableTime: ''
        }
    },
    created() {
        this.endTime = this.startTime.plus({seconds: (this.time / 1000)});
        this.displayTime();
        this.interval = setInterval(() => {
            this.displayTime();
        }, 1000);
    },
    beforeDestroy(){
        clearInterval(this.interval);
    },
    methods: {
        displayTime(){
            let now = DateTime.now();
            let timeLeft = this.endTime.diff(now, ['hours', 'minutes', 'seconds']).toObject();
            let {hours, minutes, seconds} = timeLeft;
            if (hours > 0){
                if (hours < 10){
                    hours = '0' + hours;
                }
            } else {
                hours = '00';
            }
            seconds = Math.round(seconds);
            if (seconds == 60){
                seconds = '00'
                minutes = minutes + 1;
            }
            if (seconds > 0){
                if (seconds < 10){
                    seconds = '0' + seconds;
                }
            } else {
                seconds = '00'
            }
            if (minutes > 0){
                if (minutes < 10){
                    minutes = '0' + minutes;
                }
            } else {
                minutes = '00';
            }
            this.readableTime = hours + ':' + minutes + ':' + seconds;
        }
    }
}
</script>