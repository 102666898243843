<template>
    <div
        class="course-whiteboard-slide styled_list--container"
        :class="`txt-dir-${textDir}`"
    >
        <div
            class="preview--container pt-3 pb-3 pl-5 pr-5"
            :class="color"
        >
            <ul
                class="list_pane"
            >
                <li
                    v-for="(bullet, bulletIndex) in blockData.styled_list.bullet_list"
                    :key="bulletIndex"
                >
                    <div class="icon_container">
                        <div
                            v-if="icon == 'number_icon'"
                            class="number_icon"
                        >
                            {{ bulletIndex + 1 }}
                        </div>

                        <div
                            class="icon_box"
                            v-if="icon != 'no_icon' && icon != 'number_icon' && icon != 'dot' && icon != 'circle' && icon != 'hyphen'"
                        >
                            <i class="fa" :class="icon"></i>
                        </div>
                        <div
                            v-if="icon != 'number_icon' && icon != 'no_icon'"
                            class="icon_pane"
                        >
                            <svg v-if="icon == 'dot'" width="6" height="6" viewBox="0 0 6 6" class="mr-2 dot_icon" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="3" cy="3" r="3" />
                            </svg>
                            <svg v-if="icon == 'circle'" width="12" height="12" viewBox="0 0 12 12" fill="none" class="mr-2 circle_icon" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="5"  stroke-width="2"/>
                            </svg>
                            <svg v-if="icon == 'hyphen'" width="12" height="2" viewBox="0 0 12 2" class="mr-2 hyphen_icon" xmlns="http://www.w3.org/2000/svg">
                                <rect width="12" height="2" />
                            </svg>
                        </div>
                    </div>
                    <div
                        class="title"
                        :class="`txt-dir-${textDir}`">
                        {{ bullet.bulletText }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';
export default {
    name: 'StyledListBlock',
    components: {},
    mixins: [
        CourseBlockMixin,
    ],

    props: {},
    data() {
        return {
        };
    },

    computed: {
        color() {
            if (this.blockData.styled_list.element_color) {
                return this.blockData.styled_list.element_color;
            }

            return ''
        },

        icon() {
            return this.blockData.styled_list.bullet_point_icon;
        }
    },

    methods:{},
};
</script>
