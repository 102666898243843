<template>
    <div>
        <transition name="fade" mode="out-in">
            <router-view />
        </transition>
        <SideNav />
    </div>
</template>
<script>

import SideNav from '../components/SideNav';

export default {
    name: 'MainDashboard',
    components: {
        SideNav
    }
}
</script>