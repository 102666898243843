var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"hotspotSlide",staticClass:"course-whiteboard-slide image_hotspot"},[_c('div',{staticClass:"banner_photo w-100 h-100 d-flex justify-content-center align-items-center",class:{
            'h-100': _vm.isWhiteboard,
            'image-area': !_vm.isWhiteboard,
        }},[_c('div',{ref:"hotspotSize",staticClass:"imageFit"},[(!_vm.blockData.hotspot_information.hotspotPreviewList)?_c('b-img',{ref:"hotspotImg",attrs:{"src":_vm.blockData.hotspot_information.hotspotList.image_url,"alt":""},on:{"load":_vm.getImgSize}}):_vm._e(),(_vm.blockData.hotspot_information.hotspotTooltip)?[_vm._l((_vm.blockData.hotspot_information.hotspotTooltip),function(hotspot,idx){return [_c('a',{key:`hotspotCircle${idx}-${_vm.uniqueID}`,staticClass:"hot-round",class:{'active': hotspot.popover},style:({
                            top: `${hotspot.top}%`,
                            left: `${hotspot.left}%`
                        }),attrs:{"id":`popover-target-${idx}-${_vm.uniqueID}`,"href":"javascript:void(0)"},on:{"mousedown":function($event){$event.stopPropagation();}}}),_c('b-popover',{key:`hotspotPopover${idx}-${_vm.uniqueID}`,attrs:{"target":`popover-target-${idx}-${_vm.uniqueID}`,"triggers":"focus hover","custom-class":"overlay-popover hotspot-popover"},on:{"show":function($event){return _vm.onShow(idx)}}},[_c('h3',{class:`txt-dir-${_vm.textDir}`},[_vm._v(_vm._s(hotspot.title!=''?hotspot.title:'Enter title'))]),_c('p',{class:`txt-dir-${_vm.textDir}`},[_vm._v(_vm._s(hotspot.content!=''?hotspot.content:'Enter details'))])])]})]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }