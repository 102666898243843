<template>
    <b-list-group-item class="assessment__item" :href="'/assessments/' + assessment.id" @click.prevent="select">
        <h2>{{assessment.name}}</h2>
        <small>{{ $t(status) }} - {{ duration }}<template v-if="status === 'early' && startDate"><br>{{ $t('unlocks') + ' ' + startDate }}</template><template v-if="expiry"><br>{{$t('expires') + ' ' + expiry}}</template></small>
    </b-list-group-item>
</template>
<script>

import ReadableTime from '../common/getReadableTime';
import ReadableDuration from '../common/getReadableDuration';
import AssessmentService from '../services/AssessmentService';
import checkTime from '../common/getCheckTime';

export default {
    name: 'AssessmentBlock',
    props: {
        assessment: {
            required: true
        }
    },
    data(){
        return {
            startDate: null,
            duration: null,
            status: null,
            expiry: null
        }
    },
    mounted(){
        if (this.assessment.trigger === 'Admin Triggered' && this.assessment.trigger_prop){
            this.startDate = ReadableTime.getReadableTime(this.assessment.trigger_prop);
        }
        const readableDurationArr = ReadableDuration.getReadableDuration(this.assessment.duration);
        this.duration = readableDurationArr[0] + ' ' + this.$t(readableDurationArr[1]);
        this.status = this.assessment.status;
        if (this.assessment.trigger_prop){
            this.expiry = checkTime.getExpiryDate(this.assessment.trigger_prop, {'days': 30});
        }
        if (this.assessment.status == 'Enrolled' && this.assessment.trigger_prop){
            const checkWindow = checkTime.getOutsideWindow(this.assessment.trigger_prop, {'days': 30});
            if (checkWindow === 'Expired'){
                AssessmentService.updateStatus(this.assessment.id, checkWindow);
                this.status = 'Expired';
            } else if (checkWindow === 'Early') {
                this.status = 'Early';
            }
        } else if (this.assessment.status == 'Started') {
            if (this.$store.getters.getAssessment === this.assessment.id){
                AssessmentService.updateStatus(this.assessment.id, 'Exited')
                .then(() => {
                    this.$store.dispatch('setAssessment', null);
                });
                this.status = 'exited';
            } else {
                this.status = 'started';
            }
        } else if (this.assessment.status == 'Exited') {
            this.status = 'exited';
            if (this.$store.getters.getAssessment === this.assessment.id){
                this.$store.dispatch('setAssessment', null);
            }
        } else if (this.assessment.status == 'Expired'){
            this.status = 'expired';
        } else if (this.assessment.status != 'Submitted' && this.assessment.status.startsWith('Submitted')){
            this.status = 'submitted'
        }
        this.status = this.status.toLowerCase();
    },
    methods: {
        select() {
            switch (this.status) {
                case 'enrolled':
                    this.$router.push('/assessments/' + this.assessment.id);
                    break;
                case 'expired':
                    alert(this.$t('lateAssessment'));
                    break;
                case 'exited':
                    alert(this.$t('appClosed'));
                    break;
                case 'submitted':
                case 'submitted pending evidence':
                case 'submitted with evidence':
                case 'submitted without evidence':
                    alert(this.$t('completedAssessmentError'));
                    break;
                case 'started':
                    alert(this.$t('startedError'));
                    break;
                default:
                    alert(this.$t('earlyAssessment'));
                    break;
            }
        }
    }
}

</script>