<template>
    <div class="course-whiteboard-slide audio_block h-100 w-100 d-flex justify-content-center align-items-center">
        <audio controls>
            <source
                :src="blockData.audioUrl"
                type="audio/mpeg"
            >
            Your browser does not support the audio element.
        </audio>
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';
export default {
    name: 'AudioBlock',
    components: {},
    mixins: [
        CourseBlockMixin,
    ],

    props: {},
    data() {
        return {
        };
    },

    methods:{},
};
</script>
