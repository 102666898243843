<template>
    <div class="container full d-flex flex-column align-items-center justify-content-center">
        <h1 class="my-4">{{ $t('helpSupportTitle') }}</h1>
        <p class="text-center">{{ $t('helpSupportIntro') }}</p>
        <b-button class="mt-3 mb-5 py-3 px-5" variant="primary link" href="mailto:academysupport@leeaint.com">academysupport@leeaint.com</b-button>
        <h2>{{ $t('helpSupportDebugTitle') }}</h2>
        <p class="text-center">{{ $t('helpSupportDebugText') }}</p>
        <b-card>
            <b-card-text><code v-html="debugInfo"></code></b-card-text>
        </b-card>
        <h2 class="mt-5">Clear Cache</h2>
        <p class="text-center">Some common issues can be fixed by clearing your cache, this will remove any downloaded courses.</p>
        <b-button class="mt-3 py-3 px-5" variant="primary" @click="clearCache">Clear Cache</b-button>
    </div>
</template>
<script>
export default {
    name: 'HelpSupportScreen',
    computed: {
        debugInfo() {
            return `App Version: ${process.env.VUE_APP_VERSION}<br>
            User Agent: ${navigator.userAgent}<br>
            Online: ${navigator.onLine}<br>
            Speed: ${'connection' in navigator ? navigator.connection.downlink : 'Not Supported'}<br>
            Use Low Data: ${'connection' in navigator ? navigator.connection.saveData : 'Not Supported'}<br>
            Microsoft Store: ${document.referrer === 'app-info://platform/microsoft-store' || sessionStorage.getItem('is_ms_store_pwa') ? 'true' : 'false'}`
        }
    },
    methods: {
        async clearCache() {
            await caches.delete('courses');
            this.$store.dispatch('emptyRequestQueue');
            this.$store.dispatch('toasting', '');
            window.location.reload();
        }
    }
}
</script>
