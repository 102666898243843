<template>
    <div class="quizBlock">
        <div v-html="question" class="text-center"></div>
        <b-list-group class="mt-3" :class="textDir">
            <template v-for="(option, index) in block.data.options">
                <b-list-group-item class="answer d-flex align-items-center" :class="{active: option === selectedAnswer}" :key="index" @click="select(option)">
                    <p class="m-0 flex-grow-1">{{option}}</p>
                    <div class="answer__select flex-shrink-0 ml-3 d-flex justify-content-center align-items-center">
                        <div class="answer__select__selected"></div>
                    </div>
                </b-list-group-item>
            </template>
        </b-list-group>
    </div>
</template>
<script>

export default {
    name: 'QuizBlock',
    props: {
        block: Object,
        textDir: {
            type: String,
            default: ''
        }
    },
    computed: {
        question() {
            if (this.block.data.question) {
                if (this.block.data.question.includes('src') || this.block.data.question.includes('href')) {
                    return this.block.data.question.replace(/(src="|href=")(\/)(.*?)("(>|\s))/g, `$1${process.env.VUE_APP_APIURL}$2$3$4`);
                }
                return this.block.data.question.replace(/(^\/)/g, `${process.env.VUE_APP_APIURL}$1`);
            }
            return '';
        },
    },
    data(){
        return {
            selectedAnswer: null
        }
    },
    created(){
        if (this.block.response){
            this.selectedAnswer = this.block.response[0];
        }
    },
    methods: {
        select(selected){
            this.selectedAnswer = selected;
            this.$emit('answerSelected', this.selectedAnswer);
        }
    }
}

</script>
