<template>
    <div class="course-whiteboard-slide key_information_panel">
        <div
            v-if="blockData.key_information.block_color"
            class="key-box"
            :class="`bg_${color}`"
        >
            <div class="key-box-div">
                <div :class="textDir == 'rtl' ? 'ml-3' : 'mr-3'">
                        <i
                            class="fa"
                            :class="icon"
                            aria-hidden="true"
                        />
                </div>
                <div class="text-color">
                    <h4>{{ blockData.key_information.block_title ? blockData.key_information.block_title : '' }}</h4>
                    <div v-html="blockData.key_information.block_quote" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';
export default {
    name: 'KeyInformationBlock',
    components: {},
    mixins: [
        CourseBlockMixin,
    ],

    props: {},
    data() {
        return {
        };
    },

    computed: {
        color() {
            if (this.blockData.key_information.block_color) {
                return this.blockData.key_information.block_color;
            }

            return '';
        },

        icon() {
            if (this.blockData.key_information.block_icon) {
                return [
                    this.blockData.key_information.block_icon,
                    'fa-2x',
                    'text-color',
                ];
            }

            return '';
        }
    },
    methods:{},
};
</script>
