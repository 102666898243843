<template>
    <div class="container full d-flex justify-content-center align-items-center">
        <b-link class="back" to="/translate"><svg-icon icon="back" /></b-link>
        <LoadingSpinner v-if="loading" />
        <template v-else>
            <validation-observer ref="observer" v-slot="{handleSubmit}">
                <b-form class="signin" @submit.stop.prevent="handleSubmit(login)">
                    <h1>{{ $t('signInAccount')}}</h1>
                        <validation-provider :name="$t('email')" rules="email|required" v-slot="validationContext">
                            <b-form-group label="Email" label-for="email" label-sr-only>
                                <b-form-input id="email" name="email" type="email" :placeholder="$t('email')" v-model="form.email" :state="getValidationState(validationContext)" autofocus @input="updateInput"></b-form-input>
                                <b-form-invalid-feedback id="email-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider :name="$t('password')" rules="required" v-slot="validationContext">
                            <b-form-group label="Password" label-for="password" label-sr-only>
                                <b-form-input id="password" name="password" type="password" :placeholder="$t('password')" v-model="form.password" :state="getValidationState(validationContext)" @input="updateInput"></b-form-input>
                                <b-form-invalid-feedback id="password-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <template v-if="msg">
                            <p id="general-message" class="invalid-feedback" style="display:block;">{{ msg }}</p>
                        </template>
                        <div class="d-flex justify-content-end mb-3">
                            <a class="signin__forgot ml-auto" href="https://elearning.leeaint.com/password/reset#/"><small>{{ $t('forgotPassword') }}</small></a>
                        </div>
                        <b-button class="mt-3 p-3" variant="primary" type="submit" block>{{ $t('signIn') }}</b-button>
                    
                </b-form>
            </validation-observer>
        </template>
    </div>
</template>
<script>

import { ValidationObserver, ValidationProvider } from "vee-validate";
import SvgIcon from '@/components/SvgIcon'
import AuthService from '../services/AuthService.js';
import LoadingSpinner from '@/components/loader/Loader';

export default {
    name: 'LoginScreen',
    data() {
        return {
            msg: null,
            loading: false,
            form: {
                email: '',
                password: ''
            }
        }
    },
    mounted() {
        this.$store.dispatch('setMaintenanceMode', [false]);
    },
    components: {
        ValidationObserver,
        ValidationProvider,
        SvgIcon,
        LoadingSpinner
    },
    methods: {
        updateInput() {
            this.msg = null;
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        async login() {
            this.msg = null;
            this.loading = true;
            try {
                const response = await AuthService.login(this.form);
                const token = response.token;
                const user = response.user;
                this.$store.dispatch('login', { token, user });
                this.$router.push('/');
            } catch (error) {
                this.loading = false;
                this.msg = error;
            }
        }
    }
}
</script>