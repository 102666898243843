import Vue from 'vue';
import VueI18n from 'vue-i18n'
import store from './store'
import translations from '@/assets/translations.json'

Vue.use(VueI18n)

export default new VueI18n({
    locale: store.getters.getLanguage ? store.getters.getLanguage : navigator.language,
    fallbackLocale: {
        'pt': 'pt-br',
        'default': 'en'
    },
    messages: translations
});