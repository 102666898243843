<template>
    <div>
        <b-link class="back" @click="$emit('proceed', 'permissions')"><svg-icon icon="back" /></b-link>
        <h1>Avoid Distractions</h1>
        <div class="text-center">
            <p>If you see a popup below which looks similar to this:</p>
            <p><img src="/img/popupscreenshot.png" width="600" /></p>
            <p>Click "Hide" to avoid accidently clicking away from the assessment.</p>
        </div>
        <b-button class="p-3 mt-5" block variant="primary" @click="$emit('proceed', 'intro')">{{ $t('continue') }}</b-button>
    </div>
</template>
<script>
import SvgIcon from '@/components/SvgIcon'
export default {
    name: "AssessmentPopup",
    components: {
        SvgIcon
    }
}

</script>