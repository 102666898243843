<template>
    <div class="container min-full">
        <button class="back" @click="refreshCourses()"><svg-icon icon="refresh" width="32" /></button>
        <h1 class="mb-5">{{ $t('myCourses') }}</h1>
        <LoadingSpinner v-if="loading" />
        <template v-else>
            <b-list-group class="flex-row flex-wrap">
                <CourseBlock v-for="course in courses" :key="course.id" :course="course" />
            </b-list-group>
            <p v-if="courses.length === 0" class="text-center">{{ $t('noCourses') }}</p>
        </template>
    </div>
</template>
<script>
import SvgIcon from '@/components/SvgIcon';
import CourseService from '../services/CourseService';
import CourseBlock from '../components/CourseBlock';
import LoadingSpinner from '@/components/loader/Loader';
import Bugsnag from '@bugsnag/js';

export default {
    name: 'CourseScreen',
    components: {
        CourseBlock,
        LoadingSpinner,
        SvgIcon
    },
    data() {
        return {
            courses: [],
            loading: true
        }
    },
    mounted(){
        this.getCourses();
        this.$store.dispatch('toasting', '');
        window.addEventListener('online', () => {
            this.refreshCourses();
        });
        window.addEventListener('offline', () => {
            this.refreshCourses();
        });
    },
    beforeDestroy(){
        window.removeEventListener('online', () => {
            this.refreshCourses();
        });
        window.removeEventListener('offline', () => {
            this.refreshCourses();
        });
    },
    methods: {
        refreshCourses() {
            this.loading = true;
            this.getCourses();
        },
        async getCourses() {
            try {
                const response = await CourseService.getCourses();
                this.loading = false;
                this.courses = response.courses;
            } catch (error) {
                this.loading = false;
                Bugsnag.notify(error);
            }
        }
    }
}
</script>