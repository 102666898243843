<template>
    <div
        class="course-whiteboard-slide styled_text_row_section"
    >
        <div
            class="preview--container pt-3 pb-4 pl-5 pr-5 h-100"
            :class="color"
        >
            <div class=" styled-text-container">
                <span
                    v-if="blockData.styled_text.include_line"
                    class="heading--line"
                />

                <span :class="`title txt-dir-${textDir}`">
                    {{blockData.styled_text.element_title}}
                </span>

                <div
                    class="paragraph mb-4"
                    :class="`text-area txt-dir-${textDir}`"
                    v-html="blockData.styled_text.element_text"
                />

                <span
                    v-if="blockData.styled_text.include_arrow"
                    class="footer--arrow"
                >
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" stroke="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.33203 11.7778L13.9987 18.4445L20.6654 11.7778"  stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';
export default {
    name: 'StyledTextBlock',
    components: {},
    mixins: [
        CourseBlockMixin,
    ],

    props: {},
    data() {
        return {
        };
    },

    computed: {
        color() {
            if (this.blockData.styled_text.element_background_color) {
                return this.blockData.styled_text.element_background_color;
            }

            return '';
        },
    },

    methods:{},
};
</script>
