<template>
    <div>
        <svg-sprite />
        <transition name="fade" mode="out-in">
            <router-view :class="$store.state.dir"/>
        </transition>
         <b-modal id="updatePopup" title="Maintenance Mode" :visible="$store.getters.getMaintenanceMode" no-close-on-backdrop no-close-on-esc hide-header-close hide-footer centered header-class="justify-content-center">
            <p class="text-center mb-0">{{ $store.getters.getMaintenanceModeMessage }}</p>
        </b-modal>
        <b-modal :title="$t('updateTitle')" :visible="updateExists" :ok-title="$t('updateButton')" no-close-on-backdrop no-close-on-esc hide-header-close ok-only centered @ok="refreshApp">{{ $t('updateText') }}</b-modal>
        <b-modal id="updatePopup" :visible="$device.mobile || $device.android || $device.ipad" hide-footer :title="$t('mobilePromptTitle')"  no-close-on-backdrop no-close-on-esc hide-header-close centered header-class="justify-content-center">
            <p class="text-center">{{ $t('mobilePromptText')}}</p>
            <p class="text-center"><b-button variant="primary" :href="$device.ios || $device.ipad ? 'https://apps.apple.com/app/leea-academy/id1637443669' : 'https://play.google.com/store/apps/details?id=com.leea'">{{ $t('mobilePromptButton') }}</b-button></p>
        </b-modal>
        <b-toast toaster="b-toaster-bottom-right" :visible="!$device.windows && installable && !$store.getters.getMaintenanceMode && !updateExists" :title="$t('installPromptTitle')">
            <p>{{ $t('installPromptText') }}</p>
            <b-button variant="primary" @click="installApp">{{ $t('installPromptButton') }}</b-button>
        </b-toast>
        <b-toast toaster="b-toaster-bottom-right" :visible="$device.windows && !$device.mobile && !fromMsStore && !$store.getters.getMaintenanceMode && !updateExists" :title="$t('installPromptTitle')">
            <p>{{ $t('installPromptMicrosoft')}}</p>
            <b-button variant="primary" href="https://apps.microsoft.com/store/detail/leea-academy/9NV2934L98T0" target="_blank">{{ $t('installPromptMicrosoftButton') }}</b-button>
        </b-toast>
        <b-toast toaster="b-toaster-bottom-right" :visible="!$device.windows && !$device.mobile && !compatBrowser && !$store.getters.getMaintenanceMode && !updateExists" :title="$t('browserPopupTitle')">
            <p>{{ $t('browserPopupText') }}</p>
        </b-toast>
        <b-toast toaster="b-toaster-bottom-right" :visible="$store.getters.getCourseRequestQueue.length > 0" :title="$t('syncCourseTitle')">
            <p>{{ $t('syncCourseText') }}</p>
        </b-toast>
    </div>
</template>

<script>
import SvgSprite from '@/components/SvgSprite';
export default {
    components: {
        SvgSprite
    },
    data() {
        return {
            refreshing: false,
            registration: null,
            updateExists: false,
            installable: false,
            deferredPrompt: null
        }
    },
    computed: {
        compatBrowser() {
            if ('mediaDevices' in navigator && 'getScreenDetails' in window && 'wakeLock' in navigator){
                return true;
            }
            return false;
        },
        fromMsStore() {
            if (document.referrer === 'app-info://platform/microsoft-store' || sessionStorage.getItem('is_ms_store_pwa') === true){
                sessionStorage.setItem('is_ms_store_pwa', true);
                return true;
            }
            return false;
        },

    },
    watch: {
        '$route':{
            handler: function (to) {
                document.title = to.meta.title ? to.meta.title + ' | LEEA' : 'LEEA'
            },
            immediate: true
        },
        '$store.state.toast': {
            handler: function () {
                if (this.$store.state.toast !== ''){
                    console.log(this.$store.state.toast);
                    this.$bvToast.toast(this.$t(this.$store.state.toast + 'Text'), {
                        title: this.$t(this.$store.state.toast + 'Title'),
                        toaster: 'b-toaster-bottom-right',
                        autoHideDelay: 10000,
                        appendToast: false
                    });
                    if (this.$store.state.toast !== 'offlineNotDownloaded' || this.$store.state.toast !== 'workingOffline') {
                        this.$store.dispatch('toasting', '');
                    }
                }
            },
            immediate: true
        }
    },
    async created(){
        document.addEventListener('swUpdated', this.updateAvailable, { once: true });
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (this.refreshing) return;
            this.refreshing = true;
            window.location.reload();
        });
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.deferredPrompt = e;
            this.installable = true;
        });
        window.addEventListener('appinstalled', () => {
            this.deferredPrompt = null;
        });
    },
    methods: {
        updateAvailable(event) {
            if (this.$route.meta.update && !this.$store.getters.getMaintenanceMode){
                this.registration = event.detail;
                this.updateExists = true;
            }
        },
        refreshApp() {
            this.updateExists = false
            if (!this.registration || !this.registration.waiting) return;
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        },
        async installApp() {
            const promptEvent = this.deferredPrompt;
            if (!promptEvent) {
                return;
            }
            promptEvent.prompt();
            this.deferredPrompt = null;
            this.installable = false;
        }
    }
}
</script>
