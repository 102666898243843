<template>
    <svg
        class="icon"
        :class="iconClass"
        :width="width"
        :height="height"
        :style="style"
    >
        <!-- :style="style" -->
        <use :xlink:href="`#${icon}`" />
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      default: "",
    },
    width: {
        type: [String, Number],
        default: '24',
    },
    height: {
        type: [String, Number],
        default: '24',
    },
    stroke: {
        type: String,
        default: null,
    },
    fill: {
        type: String,
        default: null,
    }
  },

  computed: {

      style() {
            const style = {};

            if (this.stroke) {
                style.stroke = this.stroke;
            }

            if (this.fill) {
                style.fill = this.fill;
            }

            return style;
        }
  }
};
</script>
