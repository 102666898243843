<template>
    <div class="course-whiteboard-slide holder-acc">
        <div class="main-acc">
            <div
                v-for="(accordian_info, idx) in blockTmp.accordian_information"

                :key="`accordion-${idx}`"
                :class="{'has_border' : blockTmp.accordian_information.length-1 }"
            >
                <div
                    class="p-3"
                    :class="{
                        'acc-main isCollapsed': accordian_info.isActive,
                        'pls-div' : !accordian_info.isActive
                    }"
                >
                    <div class="acc-div">
                        <div
                            class="cls-btn-div mb-3 mt-3"
                            :class="`txt-dir-${textDir}`"
                            @click="accordianToggle(idx)"
                        >
                            <button
                                class="border-0"

                                :class="{
                                    'cls-btn': accordian_info.isActive,
                                    'pls-div-btn ': !accordian_info.isActive
                                }"
                                type="button"
                            >
                                <svg-icon v-if="accordian_info.isActive" icon="minus" width="24" />
                                <svg-icon v-else icon="plus" width="24" />
                            </button>
                            <span
                                class="ml-3 accordion-heading"
                                :class="`txt-dir-${textDir}`"
                            >
                                {{ accordian_info.title ? accordian_info.title: 'Item '+ (idx +1) }}
                            </span>
                        </div>
                        <transition
                            name="accordion-item"
                            @enter="accordianEnter"
                            @after-enter="accordianLeave"
                            @before-leave="accordianEnter"
                            @after-leave="accordianLeave"
                        >
                            <div
                                v-if="accordian_info.isActive"
                                :class="'accordion text-area txt-dir-'+textDir"
                                v-html="accordian_info.editorData? accordian_info.editorData: ''"
                            />
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';
import SvgIcon from '@/components/SvgIcon'
export default {
    name: 'AccordianInformationBlock',
    components: {
        SvgIcon
    },
    mixins: [
        CourseBlockMixin
    ],
    props: {
        id:{
            type: Number,
            default: null
        }
    },
    data() {
        return {
            appUrl: process.env.VUE_APP_APIURL,
            blockTmp: {...this.blockData}
        };
    },

    methods:{
        accordianToggle(idx){
            if (!this.blockTmp.accordian_information[idx].isActive){
                this.$emit('interacted', this.id, idx);
            }
            this.blockTmp.accordian_information[idx].isActive = !this.blockTmp.accordian_information[idx].isActive;
        },
        accordianEnter: function (el) {
            el.style.height = el.scrollHeight + 'px'
        },
        accordianLeave: function (el) {
          el.style.height = ''
        },
    },
};
</script>
