<template>
    <div class="course-whiteboard-slide quote_information_panel">
        <div
            v-if="color"
            :class="color"
            class="key-box mb-3"
        >
            <div class="key-box-div">
                <svg viewBox="0 0 63 45" width="63" class="mt-3 quote-icon" height="45" xmlns="http://www.w3.org/2000/svg">
                    <path d="M63 18L49.5 18L58.5001 0.000114834L45.0001 0.000116015L36 18L36 45L63 45L63 18Z" />
                    <path d="M27 45L27 18L13.5 18L22.5 0.000118649L9.00005 0.000119829L-9.98981e-06 18L-7.62939e-06 45L27 45Z" />
                </svg>
                <div :class="'txt-dir-'+textDir +  (blockData.quote_information.quote_content ? ' text-color':'')">
                    <p class="mb-0 mt-0" v-html="blockData.quote_information.quote_content ? blockData.quote_information.quote_content : ''"></p>
                </div>

                <div :class="{'profile_sec' : Object.keys(blockData.quote_information.avatarList).length > 0 }">
                    <div class="pro_pic" v-if="Object.keys(blockData.quote_information.avatarList).length > 0" v-bind:style="{ 'background-image': 'url(' + blockData.quote_information.avatarList.image_url + ')'}"></div>
                    <div :class="blockData.quote_information.name || blockData.quote_information.title ? 'pro_info':''">
                        <h5 :class="'txt-dir-'+textDir">{{blockData.quote_information.name ? blockData.quote_information.name : ''}}</h5>
                        <p :class="'txt-dir-'+textDir">{{blockData.quote_information.title ? blockData.quote_information.title : ''}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';
export default {
    name: 'QuoteBlock',
    mixins: [
        CourseBlockMixin,
    ],

    props: {},
    data() {
        return {
        };
    },

    computed: {
        color() {
            if (this.blockData.quote_information.quote_color) {
                return this.blockData.quote_information.quote_color;
            }

            return '';
        },
    },

    methods:{},
};
</script>
