<template>
    <div
        class="loader"
        :class="{
            'fullscreen': fullscreen
        }"
    >
        <b-spinner variant="primary" label="Loading" />
    </div>
</template>

<script>
export default {
    name: 'LoadingSpinner',
    props: {
        fullscreen: {
            type: Boolean,
            default: true,
        },
    },
}
</script>
