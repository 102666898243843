<template>
    <div>
        <button v-b-toggle.sidebar class="burger"><svg-icon icon="menu" width="32" /></button>
        <b-sidebar id="sidebar" backdrop right shadow>
            <template #header>
                <button v-b-toggle.sidebar class="sidebar__close"><svg-icon icon="close" /></button>
            </template>
            <div class="px-3 py-2">
                <router-link to="/courses" class="sidebar__link">{{ $t('myCourses') }} </router-link>
                <a v-if="trySync" :disabled="syncing" href="#" class="sidebar__link" @click="$store.dispatch('syncing')">{{ $t('syncCourseTitle') }}</a>
                <router-link v-if="user.type === 'student'" to="/assessments" class="sidebar__link">{{ $t('myAssessments') }}</router-link>
                <router-link to="/translate" class="sidebar__link">{{ $t('changeLanguage') }}</router-link>
                <router-link to="/support" class="sidebar__link">{{ $t('helpSupportTitle') }}</router-link>
            </div>
            <template #footer>
                <div class="px-3 py-2">
                    <p class="sidebar__infotext text-center">{{ $t('loggedInAs') }} {{ $store.state.user.name }} ({{ $store.state.user.email }})</p>
                </div>
                <b-button class="p-3 sidebar__logout" block squared size="lg" @click="logout">{{ $t('logout') }}</b-button>
            </template>
        </b-sidebar>
     </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';

export default {
    name: 'SideNav',
    components: {
        SvgIcon
    },
    computed: {
        trySync() {
            if (this.$store.getters.getCourseRequestQueue.length && this.userOnline){
                return true;
            }
            return false;
        },
        syncing() {
            if (this.$store.getters.getSyncing){
                return true;
            }
            return false;
        }
    },
    data() {
        return {
            user: this.$store.getters.getUser,
            userOnline: navigator.onLine
        };
    },
    mounted(){
        window.addEventListener('online', () => {
            this.userOnline = true;
        });
        window.addEventListener('offline', () => {
            this.userOnline = false;
        });
    },
    beforeDestroy(){
        window.removeEventListener('online', () => {
            this.userOnline = true;
        });
        window.removeEventListener('offline', () => {
            this.userOnline = false;
        });
    },
    methods: {
        async logout() {
            this.$store.dispatch('logout');
            this.$router.push('/login');
            const cacheNames = await caches.keys();
            await Promise.all(cacheNames.map(async (cacheName) => {
                if (cacheName.startsWith('course')){
                    return await caches.delete(cacheName);
                }
            }));
            
        }
    }
}
</script>
