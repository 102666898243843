import store from '@/store';
import router from '@/router';
import TimeDiff from '../common/getTimeDiff';

export default {
    async getAssessments() {
        const url = process.env.VUE_APP_APIURL + '/api/v1/assessments/' + TimeDiff.getTimeDiff();
        const response = await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${store.getters.getToken}`
            }
        });
        if (response.ok) {
            store.dispatch('setMaintenanceMode', [false]);
            let responseJson = await response.json();
            return responseJson;
        } else if (response.status === 503) {
            const responseJson = await response.json();
            store.dispatch('setMaintenanceMode', [true, responseJson.message]);
        } else if (response.status === 401){
            store.dispatch('setMaintenanceMode', [false]);
            store.dispatch('logout');
            router.push('/login');
            const cacheNames = await caches.keys();
            await Promise.all(cacheNames.map(async (cacheName) => {
                if (cacheName.startsWith('course')){
                    return await caches.delete(cacheName);
                }
            }));
        } else {
            store.dispatch('setMaintenanceMode', [false]);
            throw new Error(response.status);
        }
    },
    async getAssessment(assessmentID) {
        const url = process.env.VUE_APP_APIURL + '/api/v1/assessment/' + assessmentID;
        const response = await fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${store.getters.getToken}`
            }
        });
        if (response.ok) {
            store.dispatch('setMaintenanceMode', [false]);
            let responseJson = await response.json();
            return responseJson;
        } else if (response.status === 503) {
            const responseJson = await response.json();
            store.dispatch('setMaintenanceMode', [true, responseJson.message]);
        } else if (response.status === 401){
            store.dispatch('setMaintenanceMode', [false]);
            store.dispatch('logout');
            router.push('/login');
            const cacheNames = await caches.keys();
            await Promise.all(cacheNames.map(async (cacheName) => {
                if (cacheName.startsWith('course')){
                    return await caches.delete(cacheName);
                }
            }));
        } else {
            store.dispatch('setMaintenanceMode', [false]);
            throw new Error(response.status);
        }
    },
    async updateStatus(assessmentID, status, failureReason) {
        const url = process.env.VUE_APP_APIURL + '/api/v1/assessment/' + assessmentID + '/update-status';
        let info = {
            'app': 'desktop',
            'version': process.env.VUE_APP_VERSION
        }
        if (failureReason){
            info.failureReason = failureReason
        }
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${store.getters.getToken}`
                },
                body: JSON.stringify({
                    'status': status,
                    'additionalInfo': info
                })
            });
            if (response.ok) {
                let responseJson = await response.json();
                if (responseJson.success){
                    store.dispatch('setMaintenanceMode', [false]);
                    return responseJson;
                } else {
                    throw new Error('Unsuccessful response');
                }
            } else {
                throw new Error(response.status);
            }
        } catch (error) {
            throw new Error(error);
        }
    },
    async submitAssessment(assessmentID, formData) {
        const url = process.env.VUE_APP_APIURL + '/api/v1/assessment/' + assessmentID + '/submit';
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${store.getters.getToken}`
                },
                body: formData
            });
            if (response.ok) {
                let responseJson = await response.json();
                if (responseJson.success){
                    store.dispatch('setMaintenanceMode', [false]);
                    return responseJson;
                } else {
                    throw new Error('Unsuccessful response');
                }
            } else {
                throw new Error(response.status);
            }
        } catch (error) {
            throw new Error(error);
        }
    }
}