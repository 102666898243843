var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"course-whiteboard-slide holder-acc"},[_c('div',{staticClass:"main-acc"},_vm._l((_vm.blockTmp.accordian_information),function(accordian_info,idx){return _c('div',{key:`accordion-${idx}`,class:{'has_border' : _vm.blockTmp.accordian_information.length-1 }},[_c('div',{staticClass:"p-3",class:{
                    'acc-main isCollapsed': accordian_info.isActive,
                    'pls-div' : !accordian_info.isActive
                }},[_c('div',{staticClass:"acc-div"},[_c('div',{staticClass:"cls-btn-div mb-3 mt-3",class:`txt-dir-${_vm.textDir}`,on:{"click":function($event){return _vm.accordianToggle(idx)}}},[_c('button',{staticClass:"border-0",class:{
                                'cls-btn': accordian_info.isActive,
                                'pls-div-btn ': !accordian_info.isActive
                            },attrs:{"type":"button"}},[(accordian_info.isActive)?_c('svg-icon',{attrs:{"icon":"minus","width":"24"}}):_c('svg-icon',{attrs:{"icon":"plus","width":"24"}})],1),_c('span',{staticClass:"ml-3 accordion-heading",class:`txt-dir-${_vm.textDir}`},[_vm._v(" "+_vm._s(accordian_info.title ? accordian_info.title: 'Item '+ (idx +1))+" ")])]),_c('transition',{attrs:{"name":"accordion-item"},on:{"enter":_vm.accordianEnter,"after-enter":_vm.accordianLeave,"before-leave":_vm.accordianEnter,"after-leave":_vm.accordianLeave}},[(accordian_info.isActive)?_c('div',{class:'accordion text-area txt-dir-'+_vm.textDir,domProps:{"innerHTML":_vm._s(accordian_info.editorData? accordian_info.editorData: '')}}):_vm._e()])],1)])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }