import { extend, localize } from "vee-validate"
import { required, email } from "vee-validate/dist/rules"

import en from '../node_modules/vee-validate/dist/locale/en.json';
import ar from '../node_modules/vee-validate/dist/locale/ar.json';
import zh from '../node_modules/vee-validate/dist/locale/zh_CN.json';
import pt from '../node_modules/vee-validate/dist/locale/pt_BR.json';
import id from '../node_modules/vee-validate/dist/locale/id.json';


localize({
    en,
    ar,
    zh,
    pt,
    id
});

extend('email', email);
extend('required', required);

