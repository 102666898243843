<template>
    <div class="course-whiteboard-slide course_tabs_panel">
        <b-tabs class="tab-box" @input="changeTab">
            <template
                v-for="(tabs_information, tIndex) in blockData.tabs_information"
            >
                <b-tab
                    :key="`tabs-${tIndex}`"
                    :title-link-class="`tab_new ml-4 txt-dir-${textDir}`"
                    :title="tabs_information.title ? tabs_information.title : ''"
                >
                    <div
                        :class="`text-area txt-dir-${textDir}`"
                        v-html="tabs_information.editorData"
                    />
                </b-tab>
            </template>
        </b-tabs>
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';
export default {
    name: 'TabsBlock',
    components: {},
    mixins: [
        CourseBlockMixin,
    ],
    props: {
        id: {
            type: Number,
            default: null
        }
    },
    data() {
        return {

        }
    },
    methods: {
        changeTab(newTabIndex){
            this.$emit('interacted', this.id, newTabIndex);
        }
    }
};
</script>
