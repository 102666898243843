<template>
    <div class="course-whiteboard-slide intro_block overflow-auto">
        <b-img
            :src="courseImage"
            fluid-grow
            alt=""
        />
        <h3 class="mt-4 mb-4 pl-4 pr-4">Course Details</h3>
        <span class="d-block pl-4 pr-4">
            <p :class="'text-area txt-dir-'+textDir" v-html="blockData.description" />
        </span>
    </div>
</template>

<script>

import CourseBlockMixin from '@/mixins/CourseBlockMixin';

export default {
    name: 'IntroBlock',
    components: {},

    mixins: [
        CourseBlockMixin,
    ],

    props: {},

    data() {
        return {
        };
    },

    computed: {
        courseImage() {
            if (this.blockData.image) {
                return this.blockData.image;
            }
            return '/img/thumbnails/nucleus-default-course-image.jpg';
        }
    },

    methods:{},
};
</script>
