import Bugsnag from '@bugsnag/js';

export default {

    async getMediaURLS(course, cache, trainer){
        let data;
        if (trainer){
            data = course.lessons;
            if (course.course_image_url){
                course.course_image_url = await this.matchMedia(course.course_image_url, cache);
            }
        } else {
            data = course.response_data;
            if (course.course_image){
                course.course_image = await this.matchMedia(course.course_image, cache);
            }
        }
        data = await Promise.all(data.map(async (lesson) => {
            lesson.pages = await Promise.all(lesson.pages.map(async (page) => {
                page.page_data = await Promise.all(page.page_data.map(async (block) => {
                    return await this.findImages(block, cache);
                }));
                return page;
            }));
            return lesson;
        }));
        if (trainer){
            course.lessons = data;
        } else {
            course.response_data = data;
        }
        return course;
    },
    async findImages(block, cache){
        switch (block.type) {
            case 'text':
                block.editorData = await this.matchMedia(block.editorData, cache);
                break;

            case 'quote_block':
                block.quote_information.quote_content = await this.matchMedia(block.quote_information.quote_content, cache);
                block.quote_information.avatarList.image_url = await this.matchMedia(block.quote_information.avatarList.image_url, cache);
                break;

            case 'key_information_block':
                block.key_information.block_quote = await this.matchMedia(block.key_information.block_quote, cache);
                break;

            case 'styled_text':
                block.styled_text.element_text = await this.matchMedia(block.styled_text.element_text, cache);
                break;

            case 'image':
                block.imageList.image_url = await this.matchMedia(block.imageList.image_url, cache);
                break;

            case 'video':
                block.videoUrl = await this.matchMedia(block.videoUrl, cache);
                break;

            case 'audio':
                block.audioUrl = await this.matchMedia(block.audioUrl, cache);
                break;

            case 'image_overlay_block':
                block.image_overlay_information.overlayList.image_url = await this.matchMedia(block.image_overlay_information.overlayList.image_url, cache);
                block.image_overlay_information.background_text = await this.matchMedia(block.image_overlay_information.background_text, cache);
                break;

            case 'hotspot_block':
                block.hotspot_information.hotspotList.image_url = await this.matchMedia(block.hotspot_information.hotspotList.image_url, cache);
                break;

            case 'tabs_block':
                block.tabs_information = await Promise.all(block.tabs_information.map(async (tab) => {
                    tab.editorData = await this.matchMedia(tab.editorData, cache);
                    return tab;
                }));
                break;

            case 'accordian_information_block':
                block.accordian_information = await Promise.all(block.accordian_information.map(async (tab) => {
                    tab.editorData  = await this.matchMedia(tab.editorData, cache);
                    return tab;
                }));
                break;

            case 'quiz':
                block.questions = await Promise.all(block.questions.map(async (question) => {
                    question.question = await this.matchMedia(question.question, cache);
                    return question;
                }));
                break;

            case 'resource':
                block.resource_list = await Promise.all(block.resource_list.map(async (resource) => {
                    resource.resource_url = await this.matchMedia(resource.resource_url, cache);
                    return resource;
                }));
                break;

            case 'flash_information_block':
                if (block.cards){
                    block.cards = await Promise.all(block.cards.map(async (card) => {
                        card.frontImage = await this.matchMedia(card.frontImage, cache);
                        return card;
                    }));
                }
                break;
        }
        return block;
    },

    async matchMedia(data, cache) {
        if (data) {
            let matches = [...data.matchAll(/(src|href)="(\/.*?)"/g)];
            if (matches.length){
                for (const match of matches) {
                    let [original, attr, path] = match;
                    if (cache === true) {
                        this.cacheMedia(process.env.VUE_APP_APIURL + path);
                    } else if (cache === 'delete') {
                        this.deleteCachedMedia(process.env.VUE_APP_APIURL + path);
                    }
                    data = data.replace(original, `${attr}="${process.env.VUE_APP_APIURL + path}"`);
                }
                return data;
            }
            matches = data.match(/(^\/)/g);
            if (matches) {
                if (cache === true) {
                    this.cacheMedia(process.env.VUE_APP_APIURL + data);
                } else if (cache === 'delete') {
                    this.deleteCachedMedia(process.env.VUE_APP_APIURL + data);
                }
                return process.env.VUE_APP_APIURL + data;
            }
            if (data.includes('s3.eu-west-2.amazonaws.com')){
                if (cache === true) {
                    this.cacheMedia(data);
                } else if (cache === 'delete') {
                    this.deleteCachedMedia(data);
                }
                return data;            
            }
            return data;
        }
        return '';
    },

    async cacheMedia(url){
        try {
            const response = await fetch(url);
            if (response.ok){
                return url;
            }
        } catch(error) {
            Bugsnag.notify(error);
            return url;
        }
    },

    async deleteCachedMedia(url){
        const cache = await caches.open('courses');
        await cache.delete(url);
        return true;
    },

    //Deprecated: Sorting done on platform
    sortData(data) {
        if (data){
            data = data.map((module) => {
                if (module.pages && module.pages.length > 0) {
                    module.pages = module.pages.sort((page1, page2) => {
                        if (page1.page_order < page2.page_order) {
                            return -1;
                        }
                        if (page1.page_order > page2.page_order) {
                            return 1;
                        }
                        return 0
                    });
                }
                return module;
            }).sort((module1, module2) => {
                if (module1.lesson_order < module2.lesson_order) {
                    return -1;
                }
                if (module1.lesson_order > module2.lesson_order) {
                    return 1;
                }
                return 0
            });
        }
        return data;
    }
}