<template>
    <div
        class="course-whiteboard-slide text_block_section"
        :class="`txt-dir-${textDir}`"
    >
        <div
            class="text_block h-100"
            :class="`text-area txt-dir-${textDir}`"
            v-html="blockData.editorData"
        />
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';
export default {
    name: 'TextBlock',
    components: {},
    mixins: [
        CourseBlockMixin,
    ],

    props: {},
    data() {
        return {
        };
    },

    methods:{},
};
</script>
