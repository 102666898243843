export default {

    getReadableDuration(duration) {
        let durationArr = duration.split(' ');
        duration = durationArr[0] + ' ';
        if (durationArr[0] == 1){
            durationArr[1] = durationArr[1].slice(0, durationArr[1].length - 1);
        }
        durationArr[1] = durationArr[1].toLowerCase();
        return durationArr;
    }
    
}