var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('svg',{staticStyle:{"display":"none"}},[_c('path',{attrs:{"id":"wavyLine","d":"M634.1 260.56l-15.4-4.27a8 8 0 0 0-9.77 5.33C592.08 317.9 521.8 448 464 448c-57.47 0-93.81-100.38-129-197.45C296.16 143.11 255.94 32 176 32 87.29 32 13.05 198.62.33 241.55a7.92 7.92 0 0 0 5.57 9.89l15.4 4.26a7.93 7.93 0 0 0 9.77-5.32C47.92 194.1 118.2 64 176 64c57.47 0 93.81 100.38 129 197.45C343.84 368.89 384.06 480 464 480c88.71 0 163-166.62 175.67-209.55a7.92 7.92 0 0 0-5.57-9.89z"}})]),_c('div',{staticClass:"drawing-mode-container",class:{'drawing-mode-container--active' : _vm.showToolbar},on:{"mouseenter":function($event){_vm.showToolbar = true},"mouseleave":function($event){_vm.showToolbar = false}}},[_c('div',{staticClass:"drawing-mode-toolbar"},[_c('v-swatches',{staticClass:"mr-3",attrs:{"swatch-size":"30","swatch-style":{
                    borderRadius: '4px',
                    border: '1px black solid'
                },"trigger-style":{
                    width: '22px',
                    height: '22px',
                    borderRadius: '4px'
                },"wrapper-style":{
                    position: 'absolute',
                    top: '40px',
                    left: '0',
                    width: '124px',
                    background: 'white',
                    display: 'flex',
                    'flex-wrap': 'wrap',
                    'z-index': 10
                },"show-border":"","swatches":_vm.colours},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('div',{staticClass:"vue-swatches__trigger"},[_c('div',{staticClass:"vue-swatches__trigger_swatch",style:({
                                backgroundColor: _vm.strokeColour,
                            })})])]},proxy:true}]),model:{value:(_vm.strokeColour),callback:function ($$v) {_vm.strokeColour=$$v},expression:"strokeColour"}}),_c('b-button-group',{staticClass:"mr-3"},[_c('b-button',{staticClass:"drawing-mode-button drawing-mode-button--line",class:{'drawing-mode-button-active' : _vm.shape === 'dash' },attrs:{"disabled":_vm.shape === 'dash',"alt":"Draw Line"},on:{"click":function($event){_vm.shape = 'dash'}}},[_c('svg',{staticClass:"icon wavyLine",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 640 512"}},[_c('use',{attrs:{"xlink:href":"#wavyLine"}})])]),_c('b-button',{staticClass:"drawing-mode-button drawing-mode-button--draw",class:{'drawing-mode-button-active' : _vm.shape === 'square' },attrs:{"disabled":_vm.shape === 'square',"alt":"Draw Square"},on:{"click":function($event){_vm.shape = 'square'}}},[_c('i',{staticClass:"fa fa-square-o",attrs:{"aria-hidden":"true"}})])],1),_c('b-input-group',{staticClass:"stroke-weight mr-3",style:({'--slider-color': _vm.strokeColour})},[_c('div',{staticClass:"dot dot-small",style:({
                        backgroundColor: _vm.strokeColour,
                        width: '3px',
                        height: '3px',
                    })}),_c('b-form-input',{attrs:{"type":"range","min":"3","max":"15"},model:{value:(_vm.strokeWidth),callback:function ($$v) {_vm.strokeWidth=$$v},expression:"strokeWidth"}}),_c('div',{staticClass:"dot dot-large",style:({
                        backgroundColor: _vm.strokeColour,
                        width: '15px',
                        height: '15px',
                    })})],1),_c('b-button-group',[_c('b-button',{staticClass:"drawing-mode-button drawing-mode-button--undo",attrs:{"alt":"Undo"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.VueCanvasDrawing.undo()}}},[_c('i',{staticClass:"fa fa-undo",attrs:{"aria-hidden":"true"}})]),_c('b-button',{staticClass:"drawing-mode-button drawing-mode-button--redo",attrs:{"alt":"redo"},on:{"click":function($event){$event.preventDefault();return _vm.$refs.VueCanvasDrawing.redo()}}},[_c('i',{staticClass:"fa fa-repeat",attrs:{"aria-hidden":"true"}})])],1)],1),_c('div',{staticClass:"drawing-mode-tag"},[_vm._v(" Drawing Mode ")])]),_c('cursor-fx',{ref:"cursor",attrs:{"hide-outside":"","color":_vm.strokeColour,"inside-size":_vm.insideSize,"disabled":""}}),_c('div',{on:{"mouseenter":_vm.mouseEnter,"mouseleave":_vm.mouseLeave}},[_c('vue-drawing-canvas',{ref:"VueCanvasDrawing",attrs:{"backgroundColor":"transparent","classes":"drawCanvas","width":_vm.width,"height":_vm.height,"color":_vm.strokeColour,"line-width":_vm.lineWidth,"stroke-type":_vm.shape,"line-join":"round"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }