<template>
    <div>
        <p v-if="showInteractPrompt" class="text-danger pt-3"><svg-icon icon="sparkles" width="32" class="d-inline-block align-bottom" />{{ $t('requiredActivity') }} &#x25BE;</p>
        <component
            :is="blockType"
            :block-data="data"
            :text-dir="textDir"
            :id="id"
            :module="module"
            :lesson="lesson"
            @submitAnswers="submitAnswers"
            @interacted="interacted"
        />
    </div>
</template>

<script>
import {
    AccordianInformationBlock,
    AudioBlock,
    EmbedBlock,
    FlashInformationBlock,
    HotspotBlock,
    ImageBlock,
    ImageOverlayBlock,
    IntroBlock,
    KeyInformationBlock,
    QuizBlock,
    QuoteBlock,
    StyledListBlock,
    StyledTextBlock,
    TabsBlock,
    TextBlock,
    VideoBlock,
    FreeTextBlock,
    ResourceBlock
} from '@/components/courses/blocks';
import SvgIcon from '@/components/SvgIcon';

export default {
    name: 'CourseBlock',
    components: {
        AccordianInformationBlock,
        AudioBlock,
        EmbedBlock,
        FlashInformationBlock,
        HotspotBlock,
        ImageBlock,
        ImageOverlayBlock,
        IntroBlock,
        KeyInformationBlock,
        QuizBlock,
        QuoteBlock,
        StyledListBlock,
        StyledTextBlock,
        TabsBlock,
        TextBlock,
        VideoBlock,
        FreeTextBlock,
        ResourceBlock,
        SvgIcon
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
        textDir: {
            type: String,
            default: '',
        },
        id: {
            type: Number,
            required: true,
        },
        module: {
            type: Number,
            required: true,
        },
        lesson: {
            type: Number,
            required: true,
        },
        notInteracted: {
            type: Boolean,
            default: false,
        },
        missed: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        blockType() {
            let blockType = this.data.type.replace(/_/g, '-');

            if (!blockType.includes('-block')) {
                blockType = `${blockType}-block`;
            }

            return blockType;
        },
        showInteractPrompt(){
            if (this.missed && this.notInteracted){
                return true;
            }
            return false;
        }
    },
    methods: {
        submitAnswers(quizIndex, type, answers){
            this.$emit('submitAnswers', quizIndex, type, answers);
        },
        interacted(id, identifier){
            this.$emit('interacted', id, identifier);
        }
    }
};
</script>
