<template>
    <div>
        <b-embed
            type="iframe"
            aspect="16by9"
            :src="blockData.url"
            allowfullscreen
        />
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';
export default {
    name: 'EmbedBlock',
    components: {},
    mixins: [
        CourseBlockMixin,
    ],

    props: {},
    data() {
        return {
        };
    },

    methods:{},
};
</script>
