<template>
    <div v-if="!loading">
        <svg-icon class="complete mx-auto" icon="completedCircle" />
        <h1 v-if="expired">{{ $t('timeExpired') }}</h1>
        <h1 class="mb-3">{{ $t('assessmentCompleted') }}</h1>
        <div class="text-center" :class="textDir" v-if="outro" v-html="outro"></div>
        <div class="text-center" v-else><p>{{ $t('thankYouAssessment') }}</p></div>
        <b-button class="p-3 mt-2" block variant="primary" @click="$emit('finish')">{{ $t('finish') }}</b-button>
    </div>
</template>
<script>
import SvgIcon from '@/components/SvgIcon';
export default {
  components: { SvgIcon },
    name: "AssessmentOutro",
    props: {
        outro: String,
        expired: Boolean,
        loading: Boolean,
        textDir: {
            type: String,
            default: ''
        }
    },
    mounted() {
        this.$emit('end');
        window.onbeforeunload = () => {
            return true;
        };
    }
}

</script>