<template>
    <section class="course-whiteboard">
        <Hooper
            ref="carousel"
            @slide="updateCarousel"
            :wheelControl="false"
            :mouseDrag="false"
            :touchDrag="false"
        >
            <Slide
                v-for="(slide, index) in slides"
                :key="index"
            >
                <CourseSlide
                    :active="index === currentSlide"
                    :slideNum="index"
                    :data="slide"
                    :textDir="textDir"
                    :isRtl="isRtl"
                />
            </Slide>
        </Hooper>

        <DrawCanvas
            v-if="drawMode"
            ref="drawCanvas"
            :colours="colours"
            :defaultColour="defaultColour"
        />

        <WhiteboardToolbar
            :carousel="$refs.carousel"
            :total-slides="totalSlides"
            v-model="currentSlide"
            :draw-mode="drawMode"
            :canvasRef="canvasRef"
            @toggleDrawMode="toggleDrawMode"
            @close="close"
        />
    </section>
</template>
<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import CourseSlide from './CourseSlide';
import DrawCanvas from '@/components/whiteboard/DrawCanvas';
import WhiteboardToolbar from './WhiteboardToolbar';
import BrandingMixin from '@/mixins/BrandingMixin';

export default {
    name: 'CourseWhiteboard',
    components: {
        CourseSlide,
        Hooper,
        Slide,
        DrawCanvas,
        WhiteboardToolbar,
    },
    mixins: [
        BrandingMixin
    ],
    props: {
        course: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            answers: {},
            totalSlides: 0,
            currentSlide: 0,
            slides: [],
            drawMode: false,
            canvasRef: null,
            defaultColour: '#000000',
            allowedBlocks: ['accordian_information_block', 'audio', 'flash_information_block', 'flash_information_block', 'hotspot_block', 'image', 'intro', 'image_overlay_block', 'key_information_block', 'quiz', 'quote_block', 'styled_list_block', 'styled_text', 'tabs_block', 'text', 'video'],
        };
    },
    mounted() {
        this.getCompanyBranding();
        this.getSlides();
    },
    computed: {
        textDir() {
            if (this.course.is_rtl) {
                return 'rtl';
            } else {
                return 'ltr';
            }
        },

        isRtl() {
            return this.course.is_rtl === 1;
        },
        colours() {
            const whitelabel = this.whitelabel;

            const customColors = [];

            if (whitelabel.colours) {
                const keys = Object.keys(whitelabel.colours);
                keys.forEach((key) => {
                    if (key !== 'primary' && key !== 'secondary') {
                        const value = whitelabel.colours[key];
                        customColors.push(this.rgbToHex(value.primary.red, value.primary.green, value.primary.blue));
                    }
                });
            }

            return [
                ...customColors,
                '#000000', // black
                '#FF0000', // red
                '#0000ff', // blue
                '#87CEEB', // skyblue
                '#FFC0CB', // pink
                '#FFA500', // orange
                '#008000', // green
                '#EE82EE', // voilet
                '#808080', // gray
                '#FFFFFF', // white
            ];
        },
    },
    methods: {
        getSlides() {
            let courseUrl = this.course.course_image_url;

            this.slides = [{
                description: this.course.description,
                image: courseUrl,
                type: 'intro',
            }];

            this.course.lessons.forEach(lesson => {
                lesson.pages.forEach(page => {
                    this.answers = {...this.answers, ...page.answers};
                    page.page_data.forEach(data => {
                        if (this.allowedBlocks.includes(data.type)) {
                            this.slides.push(data);
                        }
                    });
                });
            });

            this.totalSlides = this.slides.length;
        },

        updateCarousel(payload) {
           this.currentSlide = payload.currentSlide;
           window.dispatchEvent(new Event('resize'));
        },

        toggleDrawMode(value) {
            this.drawMode = value;
            setTimeout(() => {
                this.canvasRef = this.$refs.drawCanvas;
            }, 100);
        },

        close() {
            this.$router.push({ path: '/courses/' });
        },

        colorToHex(color) {
            if (color < 256) {
                return Math.abs(color).toString(16);
            }
            return 0;
        },

        rgbToHex(red, green, blue) {
            return `#${this.colorToHex(red)}${this.colorToHex(green)}${this.colorToHex(blue)}`;
        },
    },
};
</script>
