<template>
    <div class="container full d-flex flex-column align-items-center justify-content-center">
        <b-link v-if="$store.getters.getToken" class="back" to="/"><svg-icon icon="back" /></b-link>
        <h1 class="mb-5">{{ $t('selectLanguage') }}</h1>
        <b-list-group class="flex-row flex-wrap">
            <translateBlock v-for="language in languages" :key="language.key" :language="language"></translateBlock>
        </b-list-group>
    </div>
</template>
<script>

import languages from '@/assets/languages.json';
import translateBlock from '../components/translateBlock';
import SvgIcon from '@/components/SvgIcon';

export default {
    name: "TranslateScreen",
    components: {
        translateBlock,
        SvgIcon
    },
    data() {
        return {
            languages: []
        }
    },
    mounted() {
        this.languages = languages
    }
}

</script>