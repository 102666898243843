<template>
    <b-list-group-item class="translate__item" @click.prevent="select" :href="'/#'+language.key">
        <h2 class="translate__text m-0">{{language.localisedName}}</h2>
    </b-list-group-item>
</template>

<script>

import { localize } from 'vee-validate';

export default {
    props: {
        language: Object
    },
    methods: {
        select() {
            this.$store.dispatch('setLanguage', [this.language.key, this.language.direction]);
            localize(this.language.key);
            this.$i18n.locale = this.language.key;
            if (this.$store.getters.getToken){
                this.$router.push({ path: '/' })
            } else {
                this.$router.push({ path: '/login' })
            }
        }
    }

}
</script>