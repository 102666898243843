<template>
    <div>
        <video class="question__camera" ref="cameraPreview" autoplay></video>
        <template v-for="(block, index) in assessment.blocks">
            <transition v-if="index == question" :key="index" name="fade" mode="out-in">
                <QuizBlock :block="block" :key="index" :textDir="textDir" @answerSelected="answerSelected"/>
            </transition>
        </template>
        <div class="question__selector shadow" :class="{'show': showNav}">
            <button class="question__selector__close" @click="showNav = false"><svg-icon icon="close" width="18" height="18" /></button>
            <div class="question__selector__inner">
                <h2>{{ $t('questions') }}</h2>
                <p>{{ $t('assessmentNavigationPC')}}</p>
                <template v-if="skipped">
                    <h6>{{ $t('notAnswered')}}</h6>
                    <ul class="question__selector__list incomplete">
                        <template v-for="(block, index) in assessment.blocks">
                            <li
                                v-if="index <= lastQuestion && !block.response"
                                :key="'q' + index"
                                @click="goToQuestion(index)"
                                class="question__selector__item"
                            >
                                <div><span>{{ index + 1}}</span></div>
                                <p>{{ textOnly(block.data.question) }}</p>
                            </li>
                        </template>
                    </ul>
                </template>
                <template v-if="answered">
                    <h6>{{ $t('Answered')}}</h6>
                    <ul class="question__selector__list completed">
                        <template v-for="(block, index) in assessment.blocks">
                            <li
                                v-if="index <= lastQuestion && block.response"
                                :key="'q' + index"
                                @click="goToQuestion(index)"
                                class="question__selector__item"
                            >
                                <div><span>{{ index + 1}}</span></div>
                                <p>{{ textOnly(block.data.question) }}</p>
                            </li>
                        </template>
                    </ul>
                </template>
            </div>
        </div>
        <div class="fixed-bottom shadow bg-white">
            <div class="container mid2">
                <AssessmentTimer
                    :time="time"
                    :questionNum="question + 1"
                    :totalQuestions="assessment.blocks.length"
                />
                <div class="question__buttons d-flex">
                    <b-button v-if="question !== 0" variant="primary" class="mr-2 p-3" @click="goBack()">{{ $t('previousQuestion') }}</b-button>
                    <b-button v-if="question !== assessment.blocks.length -1" class="p-3" variant="primary" @click="goForward()">{{ nextText }}</b-button>
                    <b-button v-if="seenAll" class="ml-2 p-3" variant="primary" @click="submit()">{{ $t('submit') }}</b-button>
                </div>
            </div>
            <button v-if="lastQuestion > 0" class="question__menuBtn" @click="toggleMenu()" v-b-tooltip.topleft :title="$t('questionBank')"><svg-icon icon="menu" /></button>
        </div>
    </div>
</template>
<script>

import QuizBlock from '../blocks/QuizBlock'
import SvgIcon from '@/components/SvgIcon';
import AssessmentTimer from './AssessmentTimer.vue';
import Bugsnag from '@bugsnag/js'

export default {
    name: "AssessmentExam",
    props: {
        assessment: Object,
        textDir: {
            type: String,
            default: ''
        },
        time: {
            type: Number,
            required: true
        },
        cameraStream: MediaStream
    },
    components: {
        QuizBlock,
        SvgIcon,
        AssessmentTimer
    },
    data() {
        return {
            lastQuestion: 0,
            question: 0,
            seenAll: false,
            showNav: false
        }
    },
    computed: {
        nextText() {
            if (this.assessment.blocks[this.question].response){
                return this.$t('nextQuestion');
            } else {
                return this.$t('skipQuestion');
            }
        },
        skipped() {
            for (let index = 0; index <= this.lastQuestion - 1; index++) {
                if (!this.assessment.blocks[index].response){
                    return true;
                }
            }
            return false;
        },
        answered() {
            for (let index = 0; index <= this.lastQuestion - 1; index++) {
                if (this.assessment.blocks[index].response){
                    return true;
                }
            }
            return false;
        }
    },
    mounted() {
        this.$nextTick(() => {
            try {
                this.$refs.cameraPreview.srcObject = this.cameraStream;
            } catch (error) {
                this.$emit('cameraFail');
                Bugsnag.notify(error);
            }
        });
        window.addEventListener("contextmenu", this.disableRightClick);
        this.timeout = setTimeout(() => {
            this.$emit('proceed', 'outro', true);
        }, this.time);
    },
    destroyed() {
+        window.removeEventListener("contextmenu", this.disableRightClick);
     },
    methods: {
        answerSelected(answer) {
            this.$emit('response', this.question, answer);
            if (this.question === this.assessment.blocks.length - 1){
                this.showNav = true;
            }
        },
        goBack() {
            this.question--;
            this.showNav = false;
        },
        goToQuestion(index){
            this.question = index;
            this.showNav = false;
        },
        goForward() {
            this.question++;
            if (this.question > this.lastQuestion){
                this.lastQuestion = this.question;
            }
            if (this.question === this.assessment.blocks.length - 1){
                this.seenAll = true;
            }
        },
        disableRightClick(e) {
            e.preventDefault();
        },
        submit() {
            this.$bvModal.msgBoxConfirm('Are you sure you want to submit?', {
                okTitle: 'Submit',
                centered: true
            }).then(value => {
                if (value){
                    this.$emit('proceed', 'outro');
                }
            });            
        },
        toggleMenu(){
            this.showNav = !this.showNav;
        },
        textOnly(input) {
            return input.replaceAll(/<[^>]*>/g, '');
        }
    }
}

</script>