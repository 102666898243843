
<template>
    <div class="course-whiteboard-slide image_overlay">
        <div
            class="banner_photo w-100 h-100"
            :class="colorClass"
        >
            <div
                class="banner_photo_img"
                v-bind:style="{ 'background-image': `url(${blockData.image_overlay_information.overlayList.image_url})`}"
            />
            <div
                class="banner_text"
                :class="`txt-dir-${textDir}`"
            >
                <div class="text-area banner_text_scroll" v-html="blockData.image_overlay_information.background_text ? blockData.image_overlay_information.background_text : ''" />
            </div>
        </div>
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';

export default {
    name: 'ImageOverlayBlock',
    components: {},

    mixins: [CourseBlockMixin],

    props: {},
    data() {
        return {
        };
    },

    computed: {
        colorClass() {
            if (this.blockData.image_overlay_information.overlay_color) {
                return this.blockData.image_overlay_information.overlay_color;
            }

            return 'transparent';
        },
    },

    methods:{},
};
</script>
