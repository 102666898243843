<template>
    <div>
        <svg style="display:none;">
            <path id="wavyLine" d="M634.1 260.56l-15.4-4.27a8 8 0 0 0-9.77 5.33C592.08 317.9 521.8 448 464 448c-57.47 0-93.81-100.38-129-197.45C296.16 143.11 255.94 32 176 32 87.29 32 13.05 198.62.33 241.55a7.92 7.92 0 0 0 5.57 9.89l15.4 4.26a7.93 7.93 0 0 0 9.77-5.32C47.92 194.1 118.2 64 176 64c57.47 0 93.81 100.38 129 197.45C343.84 368.89 384.06 480 464 480c88.71 0 163-166.62 175.67-209.55a7.92 7.92 0 0 0-5.57-9.89z"/>
        </svg>
        <div
            class="drawing-mode-container"
            :class="{'drawing-mode-container--active' : showToolbar}"
            @mouseenter="showToolbar = true"
            @mouseleave="showToolbar = false"
        >
            <div class="drawing-mode-toolbar">
                <v-swatches
                    class="mr-3"
                    v-model="strokeColour"
                    swatch-size="30"
                    :swatch-style="{
                        borderRadius: '4px',
                        border: '1px black solid'
                    }"
                    :trigger-style="{
                        width: '22px',
                        height: '22px',
                        borderRadius: '4px'
                    }"
                    :wrapper-style="{
                        position: 'absolute',
                        top: '40px',
                        left: '0',
                        width: '124px',
                        background: 'white',
                        display: 'flex',
                        'flex-wrap': 'wrap',
                        'z-index': 10
                    }"
                    show-border
                    :swatches="colours"
                >
                    <template #trigger>
                        <div
                            class="vue-swatches__trigger"
                        >
                            <div
                                class="vue-swatches__trigger_swatch"
                                :style="{
                                    backgroundColor: strokeColour,
                                }"
                            />
                        </div>
                    </template>
                </v-swatches>

                <b-button-group
                    class="mr-3"
                >
                    <b-button
                        class="drawing-mode-button drawing-mode-button--line"
                        :class="{'drawing-mode-button-active' : shape === 'dash' }"
                        :disabled="shape === 'dash'"
                        @click="shape = 'dash'"
                        alt="Draw Line"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="icon wavyLine">
                            <use xlink:href="#wavyLine" />
                        </svg>
                    </b-button>

                    <b-button
                        class="drawing-mode-button drawing-mode-button--draw"
                        :class="{'drawing-mode-button-active' : shape === 'square' }"
                        :disabled="shape === 'square'"
                        @click="shape = 'square'"
                        alt="Draw Square"
                    >
                        <i class="fa fa-square-o" aria-hidden="true"></i>
                    </b-button>
                </b-button-group>

                <b-input-group
                    class="stroke-weight mr-3"
                    :style="{'--slider-color': strokeColour}"
                >
                    <div
                        class="dot dot-small"
                        :style="{
                            backgroundColor: strokeColour,
                            width: '3px',
                            height: '3px',
                        }"
                    />
                    <b-form-input
                        v-model="strokeWidth"
                        type="range"
                        min="3"
                        max="15"
                    />
                    <div
                        class="dot dot-large"
                        :style="{
                            backgroundColor: strokeColour,
                            width: '15px',
                            height: '15px',
                        }"
                    />
                </b-input-group>

                <b-button-group>
                    <b-button
                        class="drawing-mode-button drawing-mode-button--undo"
                        alt="Undo"
                        @click.prevent="$refs.VueCanvasDrawing.undo()"
                    >
                        <i class="fa fa-undo" aria-hidden="true"></i>
                    </b-button>
                    <b-button
                        class="drawing-mode-button drawing-mode-button--redo"
                        alt="redo"
                        @click.prevent="$refs.VueCanvasDrawing.redo()"
                    >
                        <i class="fa fa-repeat" aria-hidden="true"></i>
                    </b-button>
                </b-button-group>
            </div>
            <div class="drawing-mode-tag">
                Drawing Mode
            </div>
        </div>

        <cursor-fx
            ref="cursor"
            hide-outside
            :color="strokeColour"
            :inside-size="insideSize"
            disabled
        />
        <div
            @mouseenter="mouseEnter"
            @mouseleave="mouseLeave"
        >
            <vue-drawing-canvas
                ref="VueCanvasDrawing"
                backgroundColor="transparent"
                classes="drawCanvas"
                :width="width"
                :height="height"
                :color="strokeColour"
                :line-width="lineWidth"
                :stroke-type="shape"
                line-join="round"
            />
        </div>
    </div>
</template>

<script>
import VueDrawingCanvas from 'vue-drawing-canvas';
import { CursorFx } from '@luxdamore/vue-cursor-fx';
import VSwatches from 'vue-swatches';

export default {
    name: 'DrawCanvas',
    components: {
        VueDrawingCanvas,
        CursorFx,
        VSwatches,
    },

    props: {
        defaultColour: {
            type: String,
            required: true,
        },
        colours: {
            type: Array,
            required : true,
        },
    },

    data() {
        return {
            showToolbar: false,
            strokeWidth: 3,
            strokeColour: this.defaultColour,
            shape: 'dash',
        };
    },

    computed: {
        width() {
            return window.innerWidth;
        },
        height() {
            return window.innerHeight - 60;
        },

        insideSize() {
            return `${this.strokeWidth}px`;
        },

        lineWidth() {
            return parseInt(this.strokeWidth, 10);
        },
    },

    mounted() {},

    methods:{
        mouseEnter() {
            this.$refs.cursor.start();
        },
        mouseLeave() {
            this.$refs.cursor.destroy();
        },
    },
};
</script>
