<template>
    <div class="container course-screen min-full d-flex flex-column align-items-center justify-content-center">
        <b-link class="back" to="/courses/"><svg-icon icon="back" /></b-link>
        <template v-if="course && !loading">
            <div class="w-100">
                <div v-if="isIntro" class="course-introduction">
                    <div class="course-introduction-box" :class="textDir">
                        <img :src="course.course_image" style="max-width: 100%;" />
                        <h1 class="mt-4 mb-4 pl-4 pr-4 text-center">{{course.name}}</h1>
                        <p class="'text-area pl-4 pr-4" v-html="course.description" />
                    </div>
                    <button type="button" @click="startCourse" class="btn p-3 mt-3 btn-primary btn-block">{{$t('startCourse')}}</button>
                </div>
                <div v-else>
                    <h1>{{currentModule.name}}</h1>
                    <div :class="textDir">
                        <h2 class="text-center">{{currentLesson.page_name}}</h2>
                        <CourseBlock
                            v-for="(block, blockIndex) in currentLesson.page_data"
                            :key="'' + moduleIdx + lessonIdx + blockIndex"
                            :data="currentLesson.page_data[blockIndex]"
                            :module="moduleIdx"
                            :textDir="textDir"
                            :lesson="lessonIdx"
                            :ref="blockIndex"
                            :id="blockIndex"
                            :missed="missed"
                            :notInteracted="notInteractedWith.includes(blockIndex)"
                            @submitAnswers="submitAnswers"
                            @interacted="interacted"
                        />
                    </div>
                    <div class="d-flex justify-content-between">
                        <b-button
                            variant="primary"
                            block
                            v-if="!isIntro"
                            @click="prevLesson"
                            class="p-3 mt-3 mx-3"
                        >{{$t('prevLesson')}}</b-button>
                        <b-button
                            variant="primary"
                            block
                            v-if="!(moduleIdx === course.data.length - 1 && lessonIdx === currentModule.pages.length - 1)"
                            @click="nextLesson"
                            class="p-3 mt-3 mx-3"
                        >{{$t('nextLesson')}}</b-button>
                        
                        <b-button
                            v-else
                            variant="primary"
                            :disabled="loading"
                            @click="finishCourse"
                            block
                            class="p-3 mt-3 mx-3"
                        >{{$t('finish')}}</b-button>
                    </div>
                </div>
            </div>
            <div class="question__selector tall shadow" :class="{'show': showNav}">
                <button class="question__selector__close" @click="showNav = false"><svg-icon icon="close" width="18" height="18" /></button>
                <div class="question__selector__inner">
                    <h2>{{ $t('lessonMenu') }}</h2>
                    <ul class="question__selector__list">
                        <ul class="question__selector__lessonList">
                            <li class="question__selector__courseItem" @click="isIntro = true">
                                {{ $t('courseIntro') }}
                            </li>
                        </ul>
                        <li v-for="(module, moduleIndex) in course.data" :key="'module' + module.id" class="pb-2">
                            <h5 class="mb-1">{{ module.name }}</h5>
                            <ul class="question__selector__lessonList">
                                <li 
                                    v-for="(lesson, lessonIndex) in module.pages"
                                    :key="'lesson' + lesson.id"
                                    class="question__selector__courseItem"
                                    @click="navLesson(lesson.id, lessonIndex, moduleIndex)"
                                    :class="canGoTo(lesson.id) ? '': 'disabled'"
                                >
                                    {{ lesson.page_name }}
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <button class="course__menuBtn" id="courseMenuBtn" @click="toggleMenu()"><svg-icon icon="menu" /></button>
            <b-tooltip target="courseMenuBtn" :title="$t('lessonMenu')" placement="topleft" ref="menuBtnLabel"></b-tooltip>
        </template>
        <LoadingSpinner v-if="loading" />
    </div>
</template>

<script>

import CourseService from '@/services/CourseService';
import CourseBlock from '@/components/courses/CourseBlock.vue';
import BrandingMixin from '@/mixins/BrandingMixin';
import SvgIcon from '@/components/SvgIcon';
import Bugsnag from '@bugsnag/js';
import LoadingSpinner from '@/components/loader/Loader';

export default {
    name: 'CourseScreen',
    components: {
        CourseBlock,
        SvgIcon,
        LoadingSpinner
    },
    data(){
        return {
            course: null,
            id: this.$route.params.id,
            isIntro: true,
            moduleIdx: 0,
            lessonIdx: 0,
            moduleIdxTrace: 0,
            lessonIdxTrace: 0,
            courseDataReturn: [],
            loading: true,
            cacheData: null,
            missed: false,
            notInteractedWith: [],
            showNav: false,
        }
    },
    mixins: [
        BrandingMixin
    ],
    mounted(){
        this.getCompanyBranding();
        this.getCourse().then(() => {
            if (this.course && (this.course.status !== 'Submitted' && this.course.status !== 'Failed' && this.course.status !== 'Passed') && this.course.page_ids && this.course.page_ids.length) {
                const lessonID = parseInt(this.course.page_ids.at(-1));
                const index = this.findIndexFromLesson(lessonID);
                if (index !== false) {
                    this.isIntro = false;
                    this.goToLesson(index.lesson, index.module);
                    this.nextLesson();
                }
            }
        });
    },
    computed: {
        textDir() {
            if (this.course.is_rtl !== undefined){
                if (this.course.is_rtl){
                    return 'rtl';
                } else {
                    return 'ltr';
                }
            } else {
                return '';
            }
        },
        currentModule() {
            if (!this.loading){
                return this.course.data[this.moduleIdx];
            }
            return null;
        },
        currentLesson() {
            if (!this.loading){
                return this.currentModule.pages[this.lessonIdx];
            }
            return null;
        },
    },
    methods: {
        async getCourse() {
            try {
                const cache = await caches.open('courses');
                const isCached = await cache.match(process.env.VUE_APP_APIURL + '/api/v1/course/' + this.id + '?cache=true');
                const response = await CourseService.getCourse(this.id, isCached);
                this.cacheData = response;
                this.course = response.course;
                this.courseDataReturn = response.course.response_data;
                let data = response.course.response_data;
                this.course.data = this.parseAnswers(data);
                document.title = this.course.name + ' | LEEA';
                this.$store.dispatch('toasting', '');
                this.loading = false;
            } catch(error) {
                Bugsnag.notify(error);
                this.$router.push('/courses');
            }
        },

        startCourse() {
            this.isIntro = false;
            if (!this.cacheData.course.is_started){
                this.cacheData.course.is_started = 1;
                CourseService.startCourse(this.course.id, this.cacheData);
            }
            window.scrollTo(0,0);
            this.checkNotInteractedWith();
        },
        prevLesson() {
            if (this.moduleIdx === 0 && this.lessonIdx === 0){
                this.isIntro = true;
            } else {
                let newLesson = this.lessonIdx - 1;
                let newModule = null;
                if (this.lessonIdx === 0) {
                    newModule = this.moduleIdx - 1;
                    newLesson = this.course.data[this.moduleIdx - 1].pages.length - 1;
                }
                this.goToLesson(newLesson, newModule);
            }
        },
        nextLesson(click) {
            if (this.notInteractedWith.length){
                if (click){
                    this.missed = true;
                }
                this.$refs[this.notInteractedWith[0]][0].$el.scrollIntoView({
                    behavior: 'smooth'
                });
            } else {
                this.missed = false;
                if (!this.cacheData.course.page_ids){
                    this.cacheData.course.page_ids = [];
                }
                if (this.cacheData.course.page_ids.indexOf('' + this.currentLesson.id) === -1){
                    this.cacheData.course.page_ids.push('' + this.currentLesson.id);
                }
                CourseService.completeLesson(this.course.id, this.currentLesson.id, this.cacheData);
                let newLesson  = this.lessonIdx + 1;
                let newModule = null;

                if (this.lessonIdx === this.currentModule.pages.length - 1) {
                    newLesson = 0;
                    newModule = this.moduleIdx + 1;
                }
                this.goToLesson(newLesson, newModule);
            }
        },
        goToLesson(newLesson, newModule) {
            if (newModule !== null) {
                this.moduleIdx = newModule;
            }
            this.lessonIdx = newLesson;
            if (this.moduleIdx >= this.moduleIdxTrace) {
                if (this.moduleIdx > this.moduleIdxTrace || this.lessonIdx > this.lessonIdxTrace) {
                    this.lessonIdxTrace = this.lessonIdx;
                }
                this.moduleIdxTrace = this.moduleIdx;
            }
            this.checkNotInteractedWith();
            window.scrollTo(0,0);
        },
        navLesson(lessonID, lessonIndex, moduleIndex) {
            if (this.canGoTo(lessonID)){
                this.isIntro = false;
                this.goToLesson(lessonIndex, moduleIndex);
                this.showNav = false;
            }
        },
        async finishCourse() {
            if (this.notInteractedWith.length){
                this.missed = true;
                this.$refs[this.notInteractedWith[0]][0].$el.scrollIntoView({
                    behavior: 'smooth'
                });
            } else {
                this.loading = true;
                if (this.course.status === 'Not Submitted') {
                    await CourseService.finishCourse(this.course.id, this.cacheData);
                    this.cacheData.course.status = 'Submitted';
                }
                this.$router.push('/courses');
            }
        },
        toggleMenu(){
            this.$refs.menuBtnLabel.$emit('close');
            this.showNav = !this.showNav;
        },
        submitAnswers(quizIndex, type, answers){
            if (type === 'multiple') {
                this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[quizIndex].questions[0].answers = answers;
				this.courseDataReturn[this.moduleIdx].pages[this.lessonIdx].page_data[quizIndex].questions[0].answers = answers;
            } else if (type === 'free-text') {
                this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[quizIndex].answer = answers;
                this.courseDataReturn[this.moduleIdx].pages[this.lessonIdx].page_data[quizIndex].answer = answers;
            } else {
                this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[quizIndex].questions[0].answers = [answers];
                this.courseDataReturn[this.moduleIdx].pages[this.lessonIdx].page_data[quizIndex].questions[0].answers = [answers];
            }
            this.cacheData.course.response_data = this.courseDataReturn;
			CourseService.submitQuiz(this.$route.params.id, this.courseDataReturn, this.cacheData);
            this.checkNotInteractedWith();
        },
        interacted(id, identifier) {
            const type = this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[id].type;
            switch (type) {
                case 'tabs_block':
                    this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[id].tabs_information[identifier].viewed = true;
                    break;
                case 'hotspot_block':
                    this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[id].hotspot_information.hotspotTooltip[identifier].viewed = true;
                    break;
                case 'accordian_information_block':
                    this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[id].accordian_information[identifier].viewed = true;
                    break;
                case 'flash_information_block':
                    if (this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[id].cards){
                        this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[id].cards[identifier].viewed = true;
                    } else {
                        this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data[id].flash_information.carouselCards[identifier].viewed = true;
                    }
                    break;
            }
            this.checkNotInteractedWith();
        },
        checkNotInteractedWith() {
            this.notInteractedWith = [];
            if ((this.course.status === 'Submitted' || this.course.status === 'Failed' || this.course.status === 'Passed') ||
                (Array.isArray(this.course.page_ids) && this.course.page_ids.includes('' + this.currentLesson.id))){
                return;
            }
            this.course.data[this.moduleIdx].pages[this.lessonIdx].page_data.forEach((block, blockIndex) => {
                if (['tabs_block', 'hotspot_block', 'accordian_information_block', 'flash_information_block', 'quiz', 'free-text'].includes(block.type)) {
                    switch (block.type) {
                        case 'tabs_block':
                            for (const tab of block.tabs_information) {
                                if (!tab.viewed){
                                    this.notInteractedWith.push(blockIndex)
                                    break;
                                }
                            }
                            break;
                        case 'hotspot_block':
                            for (const hotspot of block.hotspot_information.hotspotTooltip) {
                                if (!hotspot.viewed){
                                    this.notInteractedWith.push(blockIndex)
                                    break;
                                }
                            }
                            break;
                        case 'accordian_information_block':
                            for (const accordian of block.accordian_information) {
                                if (!accordian.viewed){
                                    this.notInteractedWith.push(blockIndex)
                                    break;
                                }
                            }
                            break;
                        case 'flash_information_block':
                            if (block.cards){
                                for (const flash of block.cards) {
                                    if (!flash.viewed){
                                        this.notInteractedWith.push(blockIndex)
                                    break;
                                    }
                                } 
                            } else {
                                for (const flash of block.flash_information.carouselCards) {
                                    if (!flash.viewed){
                                        this.notInteractedWith.push(blockIndex)
                                    break;
                                    }
                                } 
                            }
                            break;
                        case 'quiz':
                            if (!block.questions[0].answers){
                                this.notInteractedWith.push(blockIndex)
                                break;
                            }
                            break;
                        case 'free-text':
                            if (!block.answer){
                                this.notInteractedWith.push(blockIndex)
                                break;
                            }
                            break;
                    }
                }
            });
        },
        findIndexFromLesson(lessonID) {
            let returnIDs = false;
            this.course.data.forEach((module, indexModule) => {
                module.pages.forEach((page, indexPage) => {
                    if (page.id === lessonID) {
                        returnIDs = {
                            module: indexModule,
                            lesson: indexPage,
                        };
                    }
                });
            });

            return returnIDs;
        },
        canGoTo(lessonID){
            if (this.course.status === 'Submitted' || this.course.status === 'Failed' || this.course.status === 'Passed'){
                return true;
            } else if (Array.isArray(this.course.page_ids) && this.course.page_ids.includes('' + lessonID)){
                return true;
            } else if (this.course.data[0].pages[0].id === lessonID && this.isIntro === false){
                return true;
            } else if (Array.isArray(this.course.page_ids) && lessonID === this.getNextLessonID(parseInt(this.course.page_ids.at(-1)))){
                return true;
            }
            return false;
        },
        getNextLessonID(lessonID) {
            for (let i = 0; i < this.course.data.length; i++) {
                let pages = this.course.data[i].pages;
                for (let j = 0; j < pages.length; j++) {
                    if (pages[j].id === lessonID) {
                        if (j + 1 < pages.length) {
                            return pages[j + 1].id;
                        } else {
                            if (i + 1 < this.course.data.length && this.course.data[i + 1].pages.length > 0) {
                                return this.course.data[i + 1].pages[0].id;
                            } else {
                                return null;
                            }
                        }
                    }
                }
            }
            return null;
        },
        parseAnswers(data) {
            if (data) {
                data.forEach(page => {
                    page.pages.forEach(module => {
                        if (module.answers && Object.keys(module.answers).length) {
                            module.page_data.map(block => {
                                Object.keys(module.answers).forEach(id => {
                                    if (block.type === 'quiz' && block.questions[0].id === id) {
                                        block.questionAnswers = module.answers[id];
                                    }
                                });
                            });
                        }
                    });
                });
            }
            return data;
        }
    }

}

</script>
