<template>
    <div class="d-flex align-items-center">
      <template v-if="this.$store.state.permissions[permission]">
        <b-icon icon="check-circle-fill" variant="success" scale="1.25" :class="$store.getters.getDirection == 'rtl' ? 'ml-2' : 'mr-2'"></b-icon>
        <small class="text-muted">{{permissions[permission].successMessage}}</small>
      </template>
      <template v-else-if="this.$store.state.permissions[permission] === null">
        <b-icon icon="exclamation-circle-fill" variant="warning" scale="1.25" :class="$store.getters.getDirection == 'rtl' ? 'ml-2' : 'mr-2'"></b-icon>
        <small class="text-muted">{{permissions[permission].nullMessage}}</small>
      </template>
      <template v-else>
        <b-icon icon="exclamation-circle-fill" variant="danger" scale="1.25" :class="$store.getters.getDirection == 'rtl' ? 'ml-2' : 'mr-2'"></b-icon>
        <small class="text-muted">{{permissions[permission].failMessage}}</small>
      </template>
    </div>
</template>
<script>
export default {
  name: 'StatusInfo',
  props: {
    permission: {
      required: true,
    }
  },
  data(){
    return{
      permissions: {
        browser: {
            failMessage: this.$t('browserFail'),
            successMessage: this.$t('browserPass')
        },
        screens: {
            failMessage: this.$t('screenCountFail'),
            successMessage: this.$t('screenCountPass'),
            nullMessage: this.$t('screenCountPending')
        },
        capture: {
            failMessage: this.$t('captureFail'),
            successMessage: this.$t('capturePass')
        },
        camera: {
            failMessage: this.$t('permissionFail'),
            successMessage: this.$t('permissionPass')
        },
        fullscreen: {
          failMessage: this.$t('fullscreenFail'),
          successMessage: this.$t('fullscreenPass'),
        }
      }
    }
  }
}
</script>