<template>
    <div class="course-whiteboard-slide video_block">
        <b-embed
            :type="embedType"
            controls
            aspect="16by9"
            :src="videoUrl"
            allowfullscreen
        />
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';
export default {
    name: 'VideoBlock',
    components: {},
    mixins: [
        CourseBlockMixin,
    ],

    props: {},
    data() {
        return {
        };
    },

    computed: {
        embedType() {
            if (this.blockData.videoUrl.indexOf('youtu') > -1) {
                return 'iframe';
            }

            return 'video';
        },

        videoUrl() {
            const videoUrl = this.blockData.videoUrl;
            if(videoUrl.indexOf('youtube') > -1){
                return 'https://www.youtube.com/embed/' + videoUrl.split('v=')[1];
            } else if (videoUrl.indexOf('youtu') > -1) {
                return 'https://www.youtube.com/embed/' + videoUrl.split('youtu')[1].split('/')[1];
            } else {
                return videoUrl;
            }
        }
    },

    methods:{},
};
</script>
