<template>
    <div class="course-whiteboard-slide p-4">
        <h5 class="mb-4">{{ $t('resourceDownload') }}</h5>
        <div class="d-flex align-items-center mb-1" v-for="resource in blockData.resource_list" :key="resource.name">
            <b-button
                variant="primary"
                :href="resource.resource_url"
                target="_blank"
                class="mr-3"
            >
                {{ $t('download') }}
            </b-button>
            <p class="mb-0">{{ resource.resource_name }}</p>
        </div>
    </div>
</template>

<script>
import CourseBlockMixin from '@/mixins/CourseBlockMixin';
export default {
    name: 'ResourceBlock',
    components: {},
    mixins: [
        CourseBlockMixin,
    ],

    props: {},
    data() {
        return {
        };
    },

    methods:{},
};
</script>
