<template>
    <div>
        <b-link class="back" @click="$emit('proceed', 'agreement')"><svg-icon icon="back" /></b-link>
        <h1>{{ $t('permissions') }}</h1>
        <b-list-group>
            <b-list-group-item>
                <h4>{{ $t('browser')}}</h4>
                <p>{{ $t('browserText') }}</p>
                <div class="d-flex justify-content-between">
                    <StatusInfo permission="browser"/>
                </div>
            </b-list-group-item>
            <b-list-group-item>
                <h4>{{ $t('screenCount') }}</h4>
                <p>{{ $t('screenCountText') }}</p>
                <div class="d-flex justify-content-between align-items-end">
                    <StatusInfo permission="screens"/>
                    <b-button @click="$emit('screenCheck')" variant="primary" v-if="!this.$store.state.permissions['screens'] && !pending.screens">{{ $t('screenCountButton') }}</b-button>
                    <b-spinner v-if="pending.screens" variant="primary"></b-spinner>
                </div>
            </b-list-group-item>
            <b-list-group-item>
                <h5>{{ $t('capture')}}</h5>
                <p>{{ $t('captureText') }}</p>
                <div class="d-flex justify-content-between align-items-end">
                    <StatusInfo permission="capture"/>
                    <b-button @click="$emit('capture')" variant="primary" v-if="!this.$store.state.permissions['capture'] && !pending.capture">{{ $t('captureButton') }}</b-button>
                    <b-spinner v-if="pending.capture" variant="primary"></b-spinner>
                </div>
            </b-list-group-item>
            <b-list-group-item>
                <h5>{{ $t('camera') }}</h5>
                <p>{{ $t('cameraText') }}</p>
                <b-form-select class="mb-4" v-model="cameraInput" v-if="cameras.length > 1" :options="cameras"></b-form-select>
                <div class="d-flex justify-content-between align-items-end">
                    <StatusInfo permission="camera"/>
                    <b-button @click="$emit('camera')" variant="primary" v-if="!this.$store.state.permissions['camera'] && !pending.camera">{{ $t('cameraButton') }}</b-button>
                    <b-spinner v-if="pending.camera" variant="primary"></b-spinner>
                </div>
                <template v-if="cameraStream">
                    <video class="mt-4 videoPreview" ref="cameraPreview" autoplay></video>
                    <em class="videoCaption">Please make your clearly visible and center in the video.</em>
                </template>
            </b-list-group-item>
            <b-list-group-item>
                <h5>{{ $t('fullscreen') }}</h5>
                <p>{{ $t('fullscreenText') }}</p>
                <div class="d-flex justify-content-between align-items-end">
                    <StatusInfo permission="fullscreen"/>
                    <b-button @click="$emit('fullscreen')" variant="primary" v-if="!this.$store.state.permissions['fullscreen'] && !pending.fullscreen">{{ $t('fullscreenButton') }}</b-button>
                    <b-spinner v-if="pending.fullscreen" variant="primary"></b-spinner>
                </div>
            </b-list-group-item>
        </b-list-group>
        <b-button class="p-3" block v-if="permissionsStatus" variant="primary" @click="$emit('proceed', 'popup')">{{ $t('continue') }}</b-button>
    </div>
</template>
<script>

import StatusInfo from './StatusInfo.vue';
import SvgIcon from '@/components/SvgIcon'
export default {
    name: "AssessmentPermissions",
    components: {
        StatusInfo,
        SvgIcon
    },
    props: {
        permissionsStatus: Boolean,
        cameras: Array,
        selectedCamera: String,
        pending: Object,
        cameraStream: MediaStream
    },
    data() {
        return {
            cameraInput: null
        }
    },
    watch: {
        cameras: function(newVal) {
            this.cameraInput = newVal[0].value;
        },
        cameraStream: function(newStream) {
            this.$nextTick(() => {
                this.$refs.cameraPreview.srcObject = newStream;
            });
        },
        cameraInput: function(newVal) {
            this.$emit('cameraChange', newVal);
        }
    },
    mounted() {
        this.cameraInput = this.selectedCamera;
        this.$emit('browser');
    }
}

</script>