import { DateTime } from "luxon";

export default {

    getOutsideWindow(startTime, duration) {
        const now = DateTime.now();
        const unlockDate = DateTime.fromMillis(startTime);
        const expire = unlockDate.plus(duration);
        const unlockDiff = unlockDate.diff(now, 'seconds').toObject().seconds;
        const expireDiff = expire.diff(now, 'seconds').toObject().seconds;
        if (unlockDiff >= 0){
            return 'Early'
        } else if (expireDiff < 0) {
            return 'Expired'
        } else {
            return false;
        }
    },
    getExpiryDate(startTime, duration) {
        const unlockDate = DateTime.fromMillis(startTime);
        const expire = unlockDate.plus(duration);
        return expire.toLocaleString(DateTime.DATETIME_MED);
    }
}