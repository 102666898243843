<template>
    <div class="container min-full">
        <button class="back" @click="refreshAssessments()"><svg-icon icon="refresh" width="32" /></button>
        <h1 class="mb-5">{{ $t('myAssessments') }}</h1>
        <LoadingSpinner v-if="loading" />
        <template v-else>
            <b-list-group class="flex-row flex-wrap">
                <AssessmentBlock :assessment="assessment" v-for="assessment in assessments" :key="assessment.id"/>
            </b-list-group>
            <p v-if="assessments.length === 0" class="text-center">{{ $t('noAssessments') }}</p>
        </template>
    </div>
</template>
<script>
import SvgIcon from '@/components/SvgIcon'
import AssessmentService from '../services/AssessmentService';
import AssessmentBlock from '../components/AssessmentBlock';
import LoadingSpinner from '@/components/loader/Loader';

export default {
    name: 'AssessmentsScreen',
    components: {
        AssessmentBlock,
        LoadingSpinner,
        SvgIcon
    },
    data() {
        return {
            assessments: [],
            loading: true,
        }
    },
    mounted(){
        this.getAssessments();
    },
    methods: {
        refreshAssessments() {
            this.loading = true;
            this.getAssessments();
        },
        async getAssessments() {
            const tempAssessments = this.assessments;
            this.assessments = [];
            try {
                const response = await AssessmentService.getAssessments();
                this.assessments = response.assessments;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                if (tempAssessments){
                    this.assessments = tempAssessments;
                }
            }
        }
    }
}
</script>